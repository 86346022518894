import { gsap } from 'gsap';
import { Container, Graphics, Sprite, Texture } from 'pixi.js';
import { colorBaseHex } from 'src/pixi/configs/button.color.config';
import GameScreen, { gameScreenCountPositions } from 'src/pixi/screens/GameScreen';

import { convertHexToPixiFormat } from '@shared/utils/helpers.utils';

const config = {
  button: {
    radius: 90,
    changeColorSpeed: 0.5,
  },
};

export default class UiButton {
  private _view: Container | null = null;
  private readonly scene: GameScreen;
  private _isDestroyed: boolean = false;

  private _button: Container | null = null;
  private _buttonCircle: Graphics | null = null;
  private _buttonGradient: Sprite | null = null;

  get view(): Container {
    if (!this._view) {
      this._view = new Container();
      this._view.name = 'CoreButton';
    }
    return this._view;
  }

  get getButtonCircle(): Graphics | null {
    return this._buttonCircle;
  }

  // Изменение цвета кнопки
  set changeFillButtonCircle({ position, speed }: { position: gameScreenCountPositions; speed?: number }) {
    if (!this._buttonCircle || this._isDestroyed) return;

    try {
      gsap.to(this._buttonCircle, {
        pixi: { tint: convertHexToPixiFormat(colorBaseHex[position]) },
        duration: speed ?? config.button.changeColorSpeed,
      });
    } catch (error) {
      console.error('Failed to change button circle fill:', error);
    }
  }

  // Изменение градиента кнопки
  set changeGradientButton({ position }: { position: gameScreenCountPositions }) {
    if (!this._buttonGradient || this._isDestroyed) return;

    try {
      const gradientTexture = this.scene.gradientsBase[position];
      if (gradientTexture) {
        this._buttonGradient.texture = gradientTexture;
      }
    } catch (error) {
      console.error('Failed to change button gradient:', error);
    }
  }

  constructor(scene: GameScreen) {
    this.scene = scene;
    this.initialize();
  }

  private initialize() {
    try {
      this.createButton();
      this.adaptive();
    } catch (error) {
      console.error('Failed to initialize button:', error);
    }
  }

  /**
   * Создает кнопку с градиентом, цветной текстурой и цветной круг
   */
  private createButton() {
    try {
      const container = new Container();
      const buttonCircle = new Graphics();
      const baseTexture = Texture.from('coreBaseButton.png');

      if (!baseTexture || !this.scene.gradientsBase[0]) {
        throw new Error('Required textures not found');
      }

      const baseSprite = new Sprite(baseTexture);
      const buttonGradient = new Sprite(this.scene.gradientsBase[0]);

      // Даем названия для кнопок что бы отображать их в devtools
      container.name = 'baseButton';
      buttonCircle.name = 'buttonCircle';
      buttonGradient.name = 'buttonGradient';

      buttonGradient.anchor.set(0.48);
      buttonCircle.beginFill(0xffffff).drawCircle(0, 0, config.button.radius).endFill();

      const { width: buttonW, height: buttonH } = buttonCircle.getBounds();
      const { width: baseW, height: baseH } = baseSprite.getBounds();

      if (baseW !== 0 && baseH !== 0) {
        baseSprite.scale.set(buttonW / baseW, buttonH / baseH);
      }
      baseSprite.anchor.set(0.5);

      container.addChild(buttonGradient, buttonCircle, baseSprite);
      this.view.addChild(container);

      this._button = container;
      this._buttonGradient = buttonGradient;
      this._buttonCircle = buttonCircle;

      this.changeFillButtonCircle = { position: 0, speed: 0 };
    } catch (error) {
      console.error('Failed to create button:', error);
    }
  }

  adaptive() {
    // Add any adaptive logic here if needed
  }

  destroy() {
    this._isDestroyed = true;

    // Kill any ongoing GSAP animations
    if (this._buttonCircle) {
      gsap.killTweensOf(this._buttonCircle);
    }

    // Clean up button components
    if (this._buttonGradient) {
      this._buttonGradient.destroy({ texture: true });
      this._buttonGradient = null;
    }

    if (this._buttonCircle) {
      this._buttonCircle.destroy();
      this._buttonCircle = null;
    }

    if (this._button) {
      this._button.destroy({ children: true });
      this._button = null;
    }

    // Clean up main view
    if (this._view) {
      this._view.destroy({ children: true });
      this._view = null;
    }
  }
}
