import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';

import { appRouter } from '@app/AppRouter.tsx';
import '@app/style/animate-circles.scss';
import '@app/style/global.css';
import '@app/style/toastify.scss';
import * as Sentry from '@sentry/react';
import { getBoolean } from '@shared//utils/helpers.utils';
import '@shared/configs/i18n/i18n';
import { CustomConsoleIntegration } from '@shared/sentry/CustomConsoleIntegration';
import { store } from '@shared/store/store.ts';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

import { UserInteractionProvider } from './shared/providers/UserInteractionProvider';
import { WebAppProvider } from './shared/providers/WebAppProvider';

Sentry.init({
  dsn: 'https://64c90eb2f5506a89f34b5b6351c21e5a@o4508357207785472.ingest.de.sentry.io/4508357210079312',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), new CustomConsoleIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  attachStacktrace: true,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: getBoolean(import.meta.env.VITE_APP_AMPLITUDE_REPLAY_ENABLED, true),
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <WebAppProvider>
      <UserInteractionProvider>
        <TonConnectUIProvider manifestUrl={`${window.location.origin}/tonconnect-manifest.json`}>
          <ToastContainer
            theme="colored"
            transition={Zoom}
            closeOnClick={true}
            hideProgressBar={true}
            autoClose={1500}
          />
          <RouterProvider router={appRouter} />
        </TonConnectUIProvider>
      </UserInteractionProvider>
    </WebAppProvider>
  </Provider>,
);
