import gsap from 'gsap';
import { Application, Container, FederatedPointerEvent, Graphics, ICanvas, Sprite, Texture } from 'pixi.js';
import { ProducerCore, ScreenId } from 'src/pixi/ProducerCore';
import { colorBaseHex, colorProgressHex } from 'src/pixi/configs/button.color.config';
import { emitterBubblesButtonConfig, emitterCoinConfig } from 'src/pixi/configs/emitters.config';
import UiButton from 'src/pixi/ui/UiButton';
import UiButtonProgress from 'src/pixi/ui/UiButton.progress';
import UiButtonText, { EButtonText, IButtonTextLang } from 'src/pixi/ui/UiButton.text';
import UiFishHook from 'src/pixi/ui/UiFishHook';
import UiFisherman from 'src/pixi/ui/UiFisherman';

import { NumericRange } from '@hf/shared-common';

import { Emitter, EmitterConfigV3 } from '@pixi/particle-emitter';
import { EStageGame } from '@shared/hooks/useCoreAnimation';
import { drawCircleRadialGradient, replaceHexColorsToRGBA } from '@shared/utils/helpers.utils';

export type gameScreenCountPositions = NumericRange<0, 19>;

const configGame = {
  colorsLength: 19,
  bottomPadding: 25,
  fisherManButtonPadding: 154,
  floatLocationButtonPadding: 90,
  button: {
    mask: {
      radius: 100,
    },
  },
  circleRadialGradient: {
    width: 210,
    height: 210,
    radius: 100,
  },
};

export default class GameScreen extends Container {
  private readonly _id: ScreenId = ScreenId.Game;
  private readonly app: Application<ICanvas>;
  private _isDestroyed: boolean = false;

  private _buttonContainer: Container | null = null;
  private _buttonContainerEmitter: Container | null = null;
  private _globalTl: gsap.core.Timeline | null = null;
  private _colorBaseRgb: string[] = [];
  private _colorProgressRgb: string[] = [];
  private _gradientsBase: Texture[] = [];
  private _stageGame: EStageGame = EStageGame.ready;

  private _buttonProgress: UiButtonProgress | null = null;
  private _button: UiButton | null = null;
  private _buttonText: UiButtonText | null = null;
  private _buttonTextTap: UiButtonText | null = null;
  private _fishHook: UiFishHook | null = null;
  private _fisherman: UiFisherman | null = null;
  private _floatLocation: Sprite | null = null;
  private _touchUser: Sprite | null = null;

  private _emitter: Emitter | null = null;
  private _buttonEmitters: Emitter[] = [];

  private _scaleTween: gsap.core.Timeline | null = null;
  private _pulseSpeed: number = 0.5;
  private _lastTapTime: number = 0;
  private _tapCount: number = 0;
  private _isAnimating: boolean = false;
  private _resetTimeout: ReturnType<typeof setTimeout> | null = null;
  private _stopGame: boolean = false;
  private _disabledGame: boolean = false;
  private _disabledClick: boolean = true;

  private _buttonTouchCallback: ((event: FederatedPointerEvent) => void) | null = null;
  private _buttonClickCallback: ((event: FederatedPointerEvent) => void) | null = null;

  // Getters and setters
  get id(): ScreenId {
    return this._id;
  }

  get buttonContainer(): Container | null {
    return this._buttonContainer;
  }

  get buttonContainerEmitter(): Container | null {
    return this._buttonContainerEmitter;
  }

  get globalTl(): gsap.core.Timeline | null {
    return this._globalTl;
  }

  get gradientsBase(): Texture[] {
    return this._gradientsBase;
  }

  get fisherman(): UiFisherman | null {
    return this._fisherman;
  }

  get buttonText(): UiButtonText | null {
    return this._buttonText;
  }

  get buttonProgress(): UiButtonProgress | null {
    return this._buttonProgress;
  }

  get fishHook(): UiFishHook | null {
    return this._fishHook;
  }

  get stageGame(): EStageGame {
    return this._stageGame;
  }

  get isDestroyed(): boolean {
    return this._isDestroyed;
  }

  set setStopGame(value: boolean) {
    this._stopGame = value;
  }

  set setStageGame(value: EStageGame) {
    this._stageGame = value;
  }

  set setFloatLocationShow(value: boolean) {
    if (!this._floatLocation || this._isDestroyed) return;

    try {
      const tl = gsap.timeline();
      tl.to(
        this._floatLocation.scale,
        {
          x: value ? 2 : 0,
          y: value ? 1 : 0,
          duration: 0.1,
          ease: 'power2.in',
        },
        0,
      );
      tl.to(
        this._floatLocation,
        {
          alpha: value ? 1 : 0,
          duration: 0.1,
          ease: 'power2.in',
        },
        0,
      );
    } catch (error) {
      console.error('Failed to set float location visibility:', error);
    }
  }

  set setDisabledClick(value: boolean) {
    this._disabledClick = value;
  }

  constructor(
    app: Application<ICanvas>,
    config: {
      langButtonText: IButtonTextLang;
    },
  ) {
    super();
    const { langButtonText } = config;

    this.app = app;
    this.name = 'GameScreen';

    try {
      this.initialize(langButtonText);
    } catch (error) {
      console.error('Failed to initialize game screen:', error);
    }
  }

  private initialize(langButtonText: IButtonTextLang): void {
    if (this._isDestroyed) return;

    try {
      // Initialize containers
      this._buttonContainer = new Container();
      this._buttonContainer.name = 'Button';
      this._buttonContainer.eventMode = 'dynamic';
      this._buttonContainer.sortableChildren = true;

      this._buttonContainerEmitter = new Container();
      this._globalTl = gsap.timeline();

      // Prepare resources
      this.prepareColors();
      ProducerCore.createSceneMask(this);

      // Initialize UI components
      this._buttonProgress = new UiButtonProgress(this);
      this._button = new UiButton(this);
      this._buttonText = new UiButtonText(langButtonText);
      this._buttonTextTap = new UiButtonText(langButtonText);

      if (this._button?.getButtonCircle?.width) {
        this._fishHook = new UiFishHook(this._button.getButtonCircle.width);
      }

      // Initialize float location
      this._floatLocation = new Sprite(Texture.from('floatLocation.png'));
      this._touchUser = new Sprite(Texture.from('touchUser.png'));

      if (this._floatLocation) {
        this._floatLocation.alpha = 0;
        this._floatLocation.anchor.set(0.5);
      }
      if (this._touchUser) {
        this._touchUser.anchor.set(0.5);
        this._touchUser.scale.set(0.5);
        this._touchUser.alpha = 0;
      }

      this._fisherman = new UiFisherman();

      // Set z-indices
      if (this._button?.view) this._button.view.zIndex = 1;
      if (this._fishHook?.view) this._fishHook.view.zIndex = 2;
      if (this._buttonText?.view) this._buttonText.view.zIndex = 3;
      if (this._buttonContainerEmitter) this._buttonContainerEmitter.zIndex = 4;

      // Add children to button container
      if (
        this._buttonContainer &&
        this._button?.view &&
        this._fishHook?.view &&
        this._buttonText?.view &&
        this._buttonContainerEmitter
      ) {
        this._buttonContainer.addChild(
          this._button.view,
          this._fishHook.view,
          this._buttonText.view,
          this._buttonContainerEmitter,
        );
      }

      // Add children to main container
      if (
        this._buttonProgress?.view &&
        this._buttonContainer &&
        this._floatLocation &&
        this._touchUser &&
        this._fisherman?.view &&
        this._buttonTextTap?.view
      ) {
        this.addChild(
          this._buttonProgress.view,
          this._buttonContainer,
          this._floatLocation,
          this._fisherman.view,
          this._buttonTextTap.view,
          this._touchUser,
        );
      }

      // Set initial states
      if (this._buttonContainer) {
        this._buttonContainer.alpha = 0;
      }

      if (this._buttonProgress?.view) {
        this._buttonProgress.view.alpha = 0;
      }

      this.createButtonMask();
      this.adaptive();
      this.onButton();
    } catch (error) {
      console.error('Failed to initialize game components:', error);
    }
  }

  show(): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    const tl = gsap.timeline();
    tl.to(this, { alpha: 1, duration: 0.2 });
    return tl;
  }

  hidden(): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    const tl = gsap.timeline();
    tl.to(this, { alpha: 0, duration: 0.2 });
    return tl;
  }

  /**
   * Запуск емиттер�� на экране с указанными координатами и конфигурацией
   */
  runEmitter(
    x: number,
    y: number,
    config: EmitterConfigV3,
    view?: Container | null,
    returnEmitter?: boolean,
  ): Emitter | undefined {
    if (this._isDestroyed) return;

    try {
      const targetView = view ?? this;
      if (!targetView) return;

      const emitter = new Emitter(targetView, config);
      emitter.updateOwnerPos(x, y);

      if (returnEmitter) {
        return emitter;
      }

      if (this._emitter) {
        this._emitter.cleanup();
      }
      this._emitter = emitter;
    } catch (error) {
      console.error('Failed to run emitter:', error);
    }
  }

  /**
   * Анимация перелета тап тап
   */
  animateTapTap(): void {
    if (this._isDestroyed || !this._globalTl || !this._buttonText || !this._buttonTextTap) return;

    try {
      const targetEndTap = this._buttonText.getGlobalPosition;
      const targetStartTap = this._buttonTextTap.getGlobalPosition;

      const tlScaleTap = gsap.timeline({
        onComplete: () => {
          if (this._buttonTextTap?.view) {
            this._buttonTextTap.view.alpha = 1;
            this._buttonTextTap.view.position.set(targetStartTap.x, targetStartTap.y);
          }
        },
      });

      tlScaleTap
        .addLabel('startTap', 0)
        .add(this._buttonText.hideCurrentText(), 'startTap')
        .add(this._buttonTextTap.changeText(EButtonText.tap), 'startTap')
        .add(() => {
          if (this._buttonTextTap?.view) {
            this._buttonTextTap.view.scale.set(0.8);
          }
        }, 'startTap')
        .to(
          this._buttonTextTap.view.scale,
          {
            x: 1,
            y: 1,
            duration: 0.1,
            ease: 'power2.inOut',
            yoyo: true,
            repeat: 16,
          },
          'startTap',
        )
        .to(
          this._buttonTextTap.view,
          {
            x: targetEndTap.x,
            y: targetEndTap.y,
            duration: 2,
            ease: 'power1.inOut',
          },
          'startTap',
        )
        .addLabel('endTap', '>')
        .to(
          this._buttonTextTap.view,
          {
            alpha: 0,
            duration: 0.1,
            ease: 'power1.inOut',
          },
          'endTap',
        )
        .to(
          this._buttonTextTap.view.scale,
          {
            x: 0,
            y: 0,
            duration: 0.1,
            ease: 'power1.inOut',
          },
          'endTap',
        )
        .add(this._buttonText.changeText(EButtonText.tap), '>');

      this._globalTl.add(tlScaleTap);
    } catch (error) {
      console.error('Failed to animate tap tap:', error);
    }
  }

  /**
   * Запуск пузырей из кнопки
   */
  runButtonBubble(): void {
    if (this._isDestroyed || !this._buttonContainer || !this._buttonContainerEmitter) return;

    try {
      const emitters = [
        {
          x: -40,
          y: this._buttonContainer.height / 2 + 50,
        },
        {
          x: 0,
          y: this._buttonContainer.height / 2 + 60,
        },
        {
          x: 40,
          y: this._buttonContainer.height / 2 + 30,
        },
      ];

      emitters.forEach(({ x, y }) => {
        if (!this._buttonContainerEmitter) return;

        const emitter = this.runEmitter(x, y, emitterBubblesButtonConfig, this._buttonContainerEmitter, true);
        if (emitter) {
          this._buttonEmitters.push(emitter);
        }
      });
    } catch (error) {
      console.error('Failed to run button bubbles:', error);
    }
  }

  /**
   * Остановка пузырей в кнопке
   */
  stopButtonBubble(): void {
    if (this._isDestroyed) return;

    try {
      this._buttonEmitters.forEach((emitter) => {
        emitter.emit = false;
        setTimeout(() => {
          emitter.cleanup();
        }, 1500);
      });
      this._buttonEmitters = [];
    } catch (error) {
      console.error('Failed to stop button bubbles:', error);
    }
  }

  /**
   * Запуск игры
   */
  play(): void {
    if (this._isDestroyed || !this._globalTl || !this._fisherman) return;

    try {
      this._globalTl.add(this.showButton(EButtonText.fish)).add(this._fisherman.show());
      this._isAnimating = false;
    } catch (error) {
      console.error('Failed to play game:', error);
    }
  }

  /**
   * Остановка игры
   */
  stop(complete: () => void): void {
    if (this._isDestroyed || !this._globalTl || !this._fishHook || !this._buttonProgress || !this._fisherman) return;

    try {
      this._globalTl
        .add(this._fishHook.hideFishHook())
        .add(() => {
          if (this._buttonProgress) {
            this._buttonProgress.animateProgress(0, true);
            this.changeColors(0);
          }
        })
        .add(this.hiddenButton(EButtonText.fish), '<0.5')
        .add(this._fisherman.hide(), '<')
        .add(() => complete());
    } catch (error) {
      console.error('Failed to stop game:', error);
    }
  }

  /**
   * Блокировка кнопки
   */
  disable(): void {
    if (this._isDestroyed || !this._globalTl || !this._buttonContainer || !this._buttonProgress?.view) return;

    try {
      this._disabledGame = true;

      this._globalTl
        .to(this._buttonContainer, { duration: 0.2, alpha: 0.5, ease: 'power2.inOut' })
        .to(this._buttonProgress.view, { duration: 0.2, alpha: 0.5, ease: 'power2.inOut' });
    } catch (error) {
      console.error('Failed to disable game:', error);
    }
  }

  /**
   * Разблокировка кнопки
   */
  unlock(): void {
    if (this._isDestroyed || !this._globalTl || !this._buttonContainer || !this._buttonProgress?.view) return;

    try {
      this._disabledGame = false;

      this._globalTl
        .to(this._buttonContainer, { duration: 0.2, alpha: 1, ease: 'power2.inOut' })
        .to(this._buttonProgress.view, { duration: 0.2, alpha: 1, ease: 'power2.inOut' });
    } catch (error) {
      console.error('Failed to unlock game:', error);
    }
  }

  /**
   * Отображает кнопку с необходимым текстом
   */
  showButton(buttonType: EButtonText): gsap.core.Timeline {
    if (
      this._isDestroyed ||
      !this._buttonContainer ||
      !this._buttonProgress?.view ||
      !this._fishHook ||
      !this._buttonText
    ) {
      return gsap.timeline();
    }

    try {
      const tl = gsap.timeline();
      tl.addLabel('showButton')
        .add(() => {
          this.setDisabledClick = true;
        })
        .to(this._buttonContainer, {
          duration: 0.2,
          alpha: this._disabledGame ? 0.5 : 1,
          ease: 'power2.inOut',
        })
        .to(
          this._buttonProgress.view,
          {
            duration: 0.2,
            alpha: this._disabledGame ? 0.5 : 1,
            ease: 'power2.inOut',
          },
          '<',
        )
        .add(this._fishHook.showFishHook())
        .add(this._buttonText.changeText(buttonType), '<')
        .add(() => {
          this.setDisabledClick = false;
        });

      return tl;
    } catch (error) {
      console.error('Failed to show button:', error);
      return gsap.timeline();
    }
  }

  /**
   * Скрытие кнопки и так же меняет текст в нужное состояние
   */
  hiddenButton(buttonType: EButtonText): gsap.core.Timeline {
    if (
      this._isDestroyed ||
      !this._buttonContainer ||
      !this._buttonProgress?.view ||
      !this._fishHook ||
      !this._buttonText
    ) {
      return gsap.timeline();
    }

    try {
      const tl = gsap.timeline();
      tl.addLabel('hiddenButton')
        .add(this._fishHook.hideFishHook())
        .to(this._buttonContainer, { duration: 0.2, alpha: 0, ease: 'power2.inOut' })
        .to(this._buttonProgress.view, { duration: 0.2, alpha: 0, ease: 'power2.inOut' }, '<')
        .add(() => this._buttonText?.hideNewText(buttonType));

      return tl;
    } catch (error) {
      console.error('Failed to hide button:', error);
      return gsap.timeline();
    }
  }

  /**
   * Изменение цветов кнопки и прогресса
   */
  changeColors(progress: number): void {
    if (this._isDestroyed || !this._button || !this._buttonProgress) return;

    try {
      const { colorsLength } = configGame;
      const step = 90 / colorsLength;

      let colorIndex = Math.floor(progress / step) as gameScreenCountPositions;

      if (colorIndex >= colorsLength) {
        colorIndex = (colorsLength - 1) as gameScreenCountPositions;
      }

      this._button.changeFillButtonCircle = { position: colorIndex };
      this._button.changeGradientButton = { position: colorIndex };
      this._buttonProgress.changeFillInnerCircle = { position: colorIndex };
      this._buttonProgress.changeFillOuterCircle = { position: colorIndex };
    } catch (error) {
      console.error('Failed to change colors:', error);
    }
  }

  /**
   * Маска для кнопки что бы поплавок не вылезал за пределы кнопки
   */
  private createButtonMask(): void {
    if (this._isDestroyed || !this._buttonContainer) return;

    try {
      const mask = new Graphics();
      const { radius } = configGame.button.mask;

      mask.beginFill(0xffffff);
      mask.drawCircle(0, 0, radius);
      mask.endFill();

      this._buttonContainer.mask = mask;
      this._buttonContainer.addChild(mask);
    } catch (error) {
      console.error('Failed to create button mask:', error);
    }
  }

  /**
   * Подготовка цветов для градиентов и текстур градиентов
   */
  private prepareColors(): void {
    if (this._isDestroyed) return;

    try {
      const { width, height, radius } = configGame.circleRadialGradient;

      this._colorBaseRgb = replaceHexColorsToRGBA(colorBaseHex);
      this._colorProgressRgb = replaceHexColorsToRGBA(colorProgressHex);

      for (const color of this._colorBaseRgb) {
        const gradient = drawCircleRadialGradient(width, height, radius, radius, radius, color);
        if (gradient) {
          this._gradientsBase.push(gradient);
        }
      }
    } catch (error) {
      console.error('Failed to prepare colors:', error);
    }
  }

  /**
   * Подписка на события кнопки, тачи
   */
  private onButton(): void {
    if (this._isDestroyed || !this._buttonContainer) return;

    try {
      this._buttonContainer.on('pointerdown', this.clickButtonPointerDown.bind(this));
      this._buttonContainer.on('pointerup', this.resetButtonAnimation.bind(this));
      this._buttonContainer.on('pointerupoutside', this.resetButtonAnimation.bind(this));
    } catch (error) {
      console.error('Failed to bind button events:', error);
    }
  }

  /**
   * Анимация scale
   */
  private startButtonAnimation = (): void => {
    if (this._isDestroyed || this._disabledGame || this._isAnimating || this._stopGame || !this._buttonContainer)
      return;

    try {
      this._isAnimating = true;

      if (this._scaleTween) {
        this._scaleTween.kill();
      }

      this._scaleTween = gsap
        .timeline({
          yoyo: true,
          onComplete: () => {
            this._isAnimating = false;
            if (this._tapCount > 0) {
              this.startButtonAnimation(); // Запуск новой анимации, если были дополнительные нажатия
            }
          },
        })
        .to(this._buttonContainer.scale, {
          x: 1.05,
          y: 1.05,
          duration: this._pulseSpeed,
          ease: 'power1.inOut',
        })
        .to(this._buttonContainer.scale, {
          x: 0.9,
          y: 0.9,
          duration: this._pulseSpeed,
          ease: 'power1.inOut',
        });
    } catch (error) {
      console.error('Failed to start button animation:', error);
      this._isAnimating = false;
    }
  };

  /**
   * Сброс анимации кнопки
   */
  private resetButtonAnimation = (): void => {
    if (this._isDestroyed || this._disabledGame || this._stopGame || !this._buttonContainer) return;

    try {
      if (this._resetTimeout !== null) {
        clearTimeout(this._resetTimeout);
      }

      this._resetTimeout = setTimeout(
        () => {
          if (this._scaleTween) {
            this._scaleTween.kill();
          }
          if (this._buttonContainer && !this._isDestroyed) {
            gsap.to(this._buttonContainer.scale, {
              x: 1,
              y: 1,
              duration: 0.5,
              ease: 'power1.inOut',
            });
          }
          this._pulseSpeed = 0.5;
          this._tapCount = 0;
          this._isAnimating = false;
        },
        300 + this._pulseSpeed * 1000,
      );
    } catch (error) {
      console.error('Failed to reset button animation:', error);
    }
  };

  /**
   * Анимация кнопки при нажатии
   */
  private clickButtonPointerDown = (event: FederatedPointerEvent): void => {
    if (this._isDestroyed || this._disabledGame || this._disabledClick) return;

    try {
      this._buttonClickCallback?.(event);

      if (this._stopGame) return;

      const { x: globalX, y: globalY } = event.global;
      this.runEmitter(globalX, globalY, emitterCoinConfig);

      const currentTime = Date.now();
      const timeDiff = currentTime - this._lastTapTime;

      if (timeDiff < 300) {
        this._tapCount++;
      } else {
        this._tapCount = 1;
      }

      this._pulseSpeed = Math.max(0.1, 0.5 - this._tapCount * 0.05);
      this._lastTapTime = currentTime;

      if (!this._isAnimating) {
        this.startButtonAnimation();
      }

      this._buttonTouchCallback?.(event);
    } catch (error) {
      console.error('Failed to handle button click:', error);
    }
  };

  /**
   * Адаптивная расстановка элементов на экране
   */
  private adaptive(): void {
    if (
      this._isDestroyed ||
      !this._button?.view ||
      !this._buttonContainer ||
      !this._buttonProgress?.view ||
      !this._fishHook ||
      !this._buttonText?.view ||
      !this._buttonTextTap?.view ||
      !this._fisherman?.view ||
      !this._floatLocation ||
      !this._touchUser
    )
      return;

    try {
      const { x: screenW, y: screenH } = ProducerCore.getSizeApp;
      const { width: buttonW, height: buttonH } = this._button.view;
      const rootXButton = screenW / 2;
      const rootYButton = screenH - buttonH + configGame.bottomPadding;

      this._buttonContainer.x = rootXButton;
      this._buttonContainer.y = rootYButton;

      this._buttonProgress.view.x = rootXButton;
      this._buttonProgress.view.y = rootYButton;

      this._fishHook.setTargetY = -buttonH / 2 + 5;

      this._buttonText.view.y = buttonH / 4 - 10;

      this._buttonTextTap.view.x = rootXButton;
      this._buttonTextTap.view.y = screenH / 4;

      this._fisherman.view.x = 0;
      this._fisherman.view.y = screenH - buttonH - configGame.fisherManButtonPadding;

      this._floatLocation.x = rootXButton;
      this._floatLocation.y = screenH - buttonH - configGame.floatLocationButtonPadding;

      this._touchUser.x = rootXButton;
      this._touchUser.y = screenH - buttonH / 2;
    } catch (error) {
      console.error('Failed to adapt screen layout:', error);
    }
  }

  /**
   * Удаление компонентов из сцены
   */
  destroy(): void {
    if (this._isDestroyed) return;

    try {
      this._isDestroyed = true;
      this._globalTl?.kill();

      this._buttonProgress?.destroy();
      this._button?.destroy();
      this._buttonText?.destroy();
      this._buttonTextTap?.destroy();
      this._fishHook?.destroy();
      this._buttonContainer?.destroy();
      this._fisherman?.destroy();
      this._floatLocation?.destroy();
      this._emitter?.destroy();

      if (this._resetTimeout !== null) {
        clearTimeout(this._resetTimeout);
        this._resetTimeout = null;
      }

      this._buttonEmitters.forEach((emitter) => emitter.destroy());
      this._buttonEmitters = [];

      super.destroy();
    } catch (error) {
      console.error('Failed to destroy game screen:', error);
    }
  }

  /**
   * Для передачи события нажатия на кнопку во время игры
   */
  onButtonTouchCallback(callback: (event: FederatedPointerEvent) => void): void {
    this._buttonTouchCallback = callback;
  }

  /**
   * Для передачи события клика на кнопку до начала игры
   */
  onButtonClickCallback(callback: (event: FederatedPointerEvent) => void): void {
    this._buttonClickCallback = callback;
  }
}
