import { getBoolean } from '@shared/utils/helpers.utils';

export enum AssetLabels {
  gameScreen = 'gameScreen',
  matchScreen = 'matchScreen',
  backgrounds = 'backgrounds',
  sounds = 'sounds',
}

type NamesGame = 'coreButton' | 'coins' | 'boat' | 'personage' | 'bubble' | 'bubbleMini' | 'particleCoin' | 'interBold';
export type NamesBackground =
  | 'backgroundCore'
  // | 'backgroundDubai'
  // | 'backgroundDubaiVideo'
  | 'backgroundMoscowNewYear'
  | 'backgroundMoscowNewYearVideo';
type NamesMusic =
  | 'buttonConfirm'
  | 'buttonConfirm-json'
  | 'errorMessage'
  | 'errorMessage-json'
  | 'notification'
  | 'notification-json'
  | 'popupMessage'
  | 'popupMessage-json'
  | 'buttonClick'
  | 'buttonClick-json';
type NamesSamples = 'ambientPianoBPM82' | 'ambientPianoBPM82-json';
type NamesSounds = 'music' | 'musicData' | 'samples' | 'samplesData';
type NamesMatch = 'explosion';

export type TAssetRootNames = NamesGame | NamesBackground | NamesSounds | NamesMatch;

export interface AssetsBundle<T extends TAssetRootNames> {
  name: AssetLabels;
  enabled: boolean;
  urls: Record<T, string>;
}

type GameScreenAssets = AssetsBundle<NamesGame>;
type BackgroundsAssets = AssetsBundle<NamesBackground>;
type SoundsAssets = AssetsBundle<NamesSounds>;
type MatchScreenAssets = AssetsBundle<NamesMatch>;

export type AssetBundles = GameScreenAssets | BackgroundsAssets | MatchScreenAssets | SoundsAssets;

const baseImages = '/assets/images';
const baseScreens = '/assets/screens';
const baseSounds = '/assets/sounds';
const baseFnt = '/assets/fonts';

const gameScreenAssets: GameScreenAssets = {
  name: AssetLabels.gameScreen,
  enabled: false,
  urls: {
    coreButton: `${baseScreens}/game/coreButton/coreButton.json`,
    coins: `${baseScreens}/game/coins/coins.json`,
    boat: `${baseScreens}/game/boats/boat.json`,
    personage: `${baseScreens}/game/personages/personage.json`,
    bubble: `${baseScreens}/game/bubble/bubble.png`,
    bubbleMini: `${baseScreens}/game/bubble/bubbleMini.png`,
    particleCoin: `${baseScreens}/game/coins/particleCoin.png`,
    interBold: `${baseFnt}/fnt/inter-130.fnt`,
  },
};

const matchScreenAssets: MatchScreenAssets = {
  name: AssetLabels.matchScreen,
  enabled: false,
  urls: {
    explosion: `${baseScreens}/match/explosion/explosion.json`,
  },
};

const backgroundsAssets: BackgroundsAssets = {
  name: AssetLabels.backgrounds,
  enabled: false,
  urls: {
    backgroundCore: `${baseImages}/backgrounds/BackgroundCore.png`,
    // backgroundDubai: `${baseImages}/backgrounds/BackgroundDubai.png`,
    // backgroundDubaiVideo: `${baseImages}/backgrounds/BackgroundDubai.mp4`,
    backgroundMoscowNewYear: `${baseImages}/backgrounds/BackgroundMoscowNewYear.png`,
    backgroundMoscowNewYearVideo: `${baseImages}/backgrounds/BackgroundMoscowNewYearVideo.mp4`,
  },
};

const soundsAssets: SoundsAssets = {
  name: AssetLabels.sounds,
  enabled: !getBoolean(import.meta.env.VITE_APP_ENABLE_AUDIO, true),
  urls: {
    music: `${baseSounds}/sound-music.mp3`,
    musicData: `${baseSounds}/sound-music.json`,
    samples: `${baseSounds}/sound-samples.mp3`,
    samplesData: `${baseSounds}/sound-samples.json`,
  },
};

export { backgroundsAssets, gameScreenAssets, matchScreenAssets, soundsAssets };
