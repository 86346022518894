import { Container, DisplayObject, Texture } from 'pixi.js';

import { isObject } from '@shared/utils/is.funcs';

import { initTelegramWebApp } from './initTelegramWebApp';

export const NBSP = '\u00A0';
export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export const openLink = (link: string, options?: { try_instant_view: boolean }) => {
  initTelegramWebApp().then((webApp) => webApp.openLink(link, options));
};

export const openTelegramLink = (link: string) => {
  initTelegramWebApp().then((webApp) => webApp.openTelegramLink(link));
};

export function getBoolean(value: string | undefined | null, def: boolean): boolean {
  const stringValue = String(value).toLowerCase();
  if (stringValue === 'true') return true;
  if (stringValue === 'false') return false;

  return def;
}

export function convertHexToPixiFormat<T = string>(hexColor: string): T {
  return `0x${hexColor.replace('#', '')}` as T;
}

/**
 * @param hexColors Цвета в формате HEX
 * @returns
 */
export function replaceHexColorsToRGBA(hexColors: string[]): string[] {
  const rgbColors = hexColors.map((hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `${r}, ${g}, ${b}`;
  });
  return rgbColors;
}

/**
 * Рисует круг с радиальным градиентом
 * @param width Ширина изображения
 * @param height Высота изображения
 * @param x Координата X центра круга
 * @param y Координата Y центра круга
 * @param r Радиус круга
 * @param c Цвет заполнения
 * @returns
 */
export function drawCircleRadialGradient(
  width: number,
  height: number,
  x: number,
  y: number,
  r: number,
  c: string,
): Texture | null {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return null;

  canvas.width = width;
  canvas.height = height;

  ctx.beginPath();
  const rad = ctx.createRadialGradient(x, y, 1, x, y, r);
  rad.addColorStop(0, `rgba(${c},0.8)`);
  rad.addColorStop(0.9, `rgba(${c},0.5)`);
  rad.addColorStop(1, `rgba(${c},0.1)`);

  ctx.fillStyle = rad;
  ctx.arc(x, y, r, 0, Math.PI * 2, false);
  ctx.fill();
  return Texture.from(canvas);
}

export function drawEllipseRadialGradient(
  width: number,
  height: number,
  x: number,
  y: number,
  radiusX: number,
  radiusY: number,
  c: string,
): Texture | null {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return null;

  canvas.width = width;
  canvas.height = height;

  ctx.beginPath();
  const rad = ctx.createRadialGradient(x, y, 1, x, y, Math.max(radiusX, radiusY));
  rad.addColorStop(0, `rgba(${c},0.8)`);
  rad.addColorStop(0.9, `rgba(${c},0.5)`);
  rad.addColorStop(1, `rgba(${c},0.1)`);

  ctx.fillStyle = rad;
  ctx.ellipse(x, y, radiusX, radiusY, 0, 0, Math.PI * 2);
  ctx.fill();
  return Texture.from(canvas);
}

/**
 * Получаем значение или отдаем значение по умолчанию, если value окажется пустым
 * @param value - передаваемое значение
 * @param def - значение по умолчанию
 * */
export function getValue<T = string | number | boolean>(value: T | undefined | null, def: T): T {
  if (value !== undefined && value !== null && value !== '') return value;
  return def;
}

/**
 * Получаем позицию элемента относительно родителя
 * @param childElement
 * @param parentElement
 * @returns
 */
export function getRelativePosition(childElement: HTMLElement, parentElement: HTMLElement) {
  const childRect = childElement.getBoundingClientRect();
  const parentRect = parentElement.getBoundingClientRect();

  return {
    x: childRect.left - parentRect.left,
    y: childRect.top - parentRect.top,
  };
}

export function isDevMode(): boolean {
  return import.meta.env.VITE_APP_MODE === 'dev';
}

/**
 * Считает глобальные координаты для конца маски
 * @param endX
 * @param endY
 * @returns
 */
export function calculateGlobalPosition(
  endX: number,
  endY: number,
  view: DisplayObject | Container<DisplayObject>,
): { x: number; y: number } {
  const componentPosition = view.getGlobalPosition();
  const globalX = componentPosition.x + endX;
  const globalY = componentPosition.y + endY;

  return { x: globalX, y: globalY };
}
export function getRandomMinMax(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function hasKeyByValue(enumObject: any, value: string | number): boolean {
  for (const key in enumObject) {
    if (enumObject[key] === value) {
      return true;
    }
  }
  return false;
}

/** Возвращает Date начала текущей недели (понедельник 00:00 UTC) */
export function getCurrentWeekStartDate(): Date {
  const now = new Date();
  // Получаем текущую дату в формате UTC без времени
  const utcNow = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
  // Получаем день недели в формате UTC (0 - воскресенье, 6 - суббота)
  const dayOfWeek = utcNow.getUTCDay();

  // Вычисляем количество дней, прошедших с понедельника
  const daysSinceMonday = (dayOfWeek + 6) % 7;

  // Вычисляем дату начала недели (понедельник 00:00 UTC)
  utcNow.setUTCDate(utcNow.getUTCDate() - daysSinceMonday);
  utcNow.setUTCHours(0, 0, 0, 0);

  return utcNow;
}

/** Возвращает Date начала прошлой недели (понедельник 00:00 UTC) */
export function getLastWeekStartDate(): Date {
  const currentWeekStartDate = getCurrentWeekStartDate();
  currentWeekStartDate.setUTCDate(currentWeekStartDate.getUTCDate() - 7);
  return currentWeekStartDate;
}

export function checkEqualityDate(d1: Date, d2: Date): boolean {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  return date1.getTime() === date2.getTime();
}

export function getNumberOfSlidesFromEnum(keys: Record<string, number | string>): number {
  return Object.keys(keys).filter((key) => isNaN(Number(key))).length - 1;
}
// Сливает вместе два объекта
export function deepMerge(to: any, from: any) {
  for (const key in from) {
    const value = from[key];

    if (isObject(value)) {
      key in to === false && (to[key] = {});

      deepMerge(to[key], value);
    } else {
      to[key] = value;
    }
  }
}
