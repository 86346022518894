import { MultiplayerNomination } from '@hf/shared-common';

import { CoinIcon, MaxFishWeightIcon, QuantityFishIcon, SumFishWeightIcon } from '@shared/assets';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { NBSP } from '@shared/utils/helpers.utils';

interface IPedestalItemProps {
  className?: string;
  coins: string;
  nomination: number;
  userName: string;
  cup: string;
  place: 1 | 2 | 3;
  nominationType: MultiplayerNomination;
}

export const PedestalItem = ({
  className,
  userName,
  coins,
  nomination,
  cup,
  place,
  nominationType,
}: IPedestalItemProps) => {
  const { getLightweightUserName } = useUtils();
  const nominationIcon: Record<MultiplayerNomination, React.ReactNode> = {
    [MultiplayerNomination.SUM_WEIGHT]: <SumFishWeightIcon width={28} height={20} />,
    [MultiplayerNomination.QUANTITY]: <QuantityFishIcon width={28} height={20} />,
    [MultiplayerNomination.WEIGHT]: <MaxFishWeightIcon width={28} height={20} />,
  };

  const getTheme = (place: number): { text: string; bg: string; height: string; cupImage: string } => {
    switch (place) {
      case 1:
        return {
          text: 'text-primary-gold',
          bg: 'bg-radialGoldCircle',
          height: 'h-full',
          cupImage: 'w-[50px] top-[10px]',
        };
      case 2:
        return {
          text: 'text-primary-blueExtraLight ',
          bg: 'bg-radialSilverCircle',
          height: 'h-[75%]',
          cupImage: 'w-[45px] top-[20px]',
        };
      case 3:
        return {
          text: 'text-primary-orangeLight',
          bg: 'bg-radialBronzeCircle',
          height: 'h-[65%]',
          cupImage: 'w-[35px] top-[35px]',
        };
      default: {
        return {
          text: '',
          bg: '',
          height: '',
          cupImage: '',
        };
      }
    }
  };
  const { text, bg, height, cupImage } = getTheme(place);
  const isOpacity = coins === '0' && nomination === 0 && userName === '';
  return (
    <div
      className={`flex flex-col relative h-full w-[calc((100%-30px)/3)] justify-end ${className ?? ''} ${text} ${isOpacity ? 'opacity-40 pointer-events-none' : ''} `}
    >
      <img src={cup} className={`absolute  h-auto m-auto left-0 right-0 z-2 ${cupImage}`} />
      <div className={`bg-black/20 rounded-10 ${height} flex items-end justify-center relative overflow-hidden `}>
        <div className="flex flex-col w-full items-center z-1">
          <span className="font-bold text-[12px]">{getLightweightUserName(userName ?? '')}</span>
          <span className="flex items-center font-bold">
            {coins}
            {NBSP} <CoinIcon width={16} height={18} />
          </span>
          <span className="flex items-center w-full bg-white/20 rounded-t-10 justify-center font-bold">
            {nomination}
            {NBSP} {nominationIcon[nominationType]}
          </span>
        </div>
        <div className={`absolute m-auto w-[120%] h-[130%] opacity-40 z-0 ${bg}`}></div>
      </div>
    </div>
  );
};
