import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AmplitudeEventClient, TASK_AD_WATCH_LIMIT_MS, TaskExtUserStatus } from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import TaskList from '@pages/tasks/widgets/TaskList/TaskList';
import { taskAPI } from '@shared/api/task';
import { useGa4 } from '@shared/hooks/integration/useGa4';
import { selectUserProfile } from '@shared/store/slice/UserSlice';
import { useAppSelector } from '@shared/store/store';
import { EGa4Category, EGa4Event, EGa4EventTiming } from '@shared/types/ga4.types';
import { Wrapper } from '@shared/ui';
import { Loader } from '@shared/ui';

import { TaskInfoBanner } from './widgets/TaskList/TaskInfoBanner';

export const TasksPage = () => {
  const { i18n, t } = useTranslation();
  const { data: externalTasks, isLoading: isExternalTasksLoading } = taskAPI.useExternalTaskInfoQuery(i18n.language);
  const { data: adsInfo, isLoading: isAdsInfoLoading } = taskAPI.useAdsInfoQuery();
  const { ga4Event, ga4StartTiming, ga4EndTiming } = useGa4();
  const userProfile = useAppSelector(selectUserProfile);

  const blockId = import.meta.env.VITE_APP_ADS_GRAM_BLOCK_ID;

  const [availableTaskCount, setAvailableTaskCount] = useState(0);

  useEffect(() => {
    setAvailableTaskCount(
      (externalTasks?.filter((task) => task.isActive && task.userStatus !== TaskExtUserStatus.CLAIMED).length ?? 0) +
        (adsInfo && (!adsInfo.watchedAt || Date.now() - new Date(adsInfo.watchedAt).getTime() > TASK_AD_WATCH_LIMIT_MS)
          ? 1
          : 0) +
        (!userProfile?.tonWallet?.isActive ? 1 : 0),
    );
  }, [isAdsInfoLoading, isExternalTasksLoading]);

  useEffect(() => {
    ga4Event(EGa4Event.TasksOpened, EGa4Category.Earn);
    ga4StartTiming(EGa4Event.TasksOpened);
    amplitude.track(AmplitudeEventClient.TasksOpened);

    return () => {
      const time = ga4EndTiming(EGa4Event.TasksOpened);
      time && ga4Event(EGa4EventTiming.ViewTask, EGa4Category.Earn, '', { view_time: time });
    };
  }, []);

  const isLoading = isExternalTasksLoading || isAdsInfoLoading;

  return (
    <div className="h-full flex flex-col gap-[8px] text-blue-light">
      <TaskInfoBanner availableTaskCount={availableTaskCount} />
      <Wrapper>
        <div className="text-[14px] p-[8px] text-center">{t('task:completion')}</div>
      </Wrapper>
      <Wrapper theme="light" paddingSize="sm" className="h-[calc(100%-260px)] flex  justify-center font-bold">
        {isLoading ? (
          <Loader className="mx-auto" />
        ) : (
          <div className="h-full w-full p-[10px_10px_10px_5px] overflow-hidden overflow-y-auto">
            <TaskList
              tasks={externalTasks ?? []}
              adsInfo={adsInfo}
              blockId={blockId}
              userWallet={userProfile?.tonWallet}
              setAvailableTaskCount={setAvailableTaskCount}
            />
          </div>
        )}
      </Wrapper>
    </div>
  );
};
