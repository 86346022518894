import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EMatchModals, selectViewMatchModal, setViewMatchModals } from '@shared/store/slice/MatchSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { Button } from '@shared/ui';
import { Modal } from '@shared/widgets';

export const CancelledFastMatchModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => selectViewMatchModal(state, EMatchModals.cancelledMatch));
  const closeModal = () => {
    dispatch(setViewMatchModals({ modal: EMatchModals.cancelledMatch, value: false }));
  };
  useEffect(() => {
    return () => {
      closeModal();
    };
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} title={t('multiplayer:modal.cancelledFastMatch.title')} onClose={closeModal}>
        <span className="text-center">{t('multiplayer:modal.cancelledFastMatch.description')}</span>
        <Button size="lg" onClick={closeModal}>
          {t('global:close')}
        </Button>
      </Modal>
    </>
  );
};
