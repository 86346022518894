// https://svgwave.in/
interface IProps {
  animated?: boolean;
  className?: string;
  fill?: string;
}
export const LoaderWaves = ({ className, animated = true, fill = '#0693e3' }: IProps) => {
  return (
    <div className={className}>
      <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 390" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 0,400 L 0,150 C 119.75,139.03571428571428 239.5,128.07142857142858 358,135 C 476.5,141.92857142857142 593.75,166.75 716,158 C 838.25,149.25 965.5,106.92857142857142 1087,100 C 1208.5,93.07142857142858 1324.25,121.53571428571429 1440,150 L 1440,400 L 0,400 Z"
          fill={fill}
        >
          <animate
            attributeName="d"
            dur={animated ? '3s' : '0s'}
            repeatCount="indefinite"
            values="
        M 0,400 L 0,150 C 119.75,139.03571428571428 239.5,128.07142857142858 358,135 C 476.5,141.92857142857142 593.75,166.75 716,158 C 838.25,149.25 965.5,106.92857142857142 1087,100 C 1208.5,93.07142857142858 1324.25,121.53571428571429 1440,150 L 1440,400 L 0,400 Z;
        M 0,400 L 0,150 C 143.85714285714283,132.32142857142856 287.71428571428567,114.64285714285714 422,121 C 556.2857142857143,127.35714285714286 680.9999999999999,157.75 783,178 C 885.0000000000001,198.25 964.2857142857144,208.35714285714286 1070,202 C 1175.7142857142856,195.64285714285714 1307.8571428571427,172.82142857142856 1440,150 L 1440,400 L 0,400 Z;
        M 0,400 L 0,150 C 107.25,174.82142857142856 214.5,199.64285714285714 325,172 C 435.5,144.35714285714286 549.25,64.25 673,75 C 796.75,85.75 930.5,187.35714285714286 1060,215 C 1189.5,242.64285714285714 1314.75,196.32142857142856 1440,150 L 1440,400 L 0,400 Z;
        M 0,400 L 0,150 C 135.82142857142858,175.78571428571428 271.64285714285717,201.57142857142858 378,183 C 484.35714285714283,164.42857142857142 561.2500000000001,101.49999999999999 686,88 C 810.7499999999999,74.50000000000001 983.3571428571427,110.42857142857142 1117,129 C 1250.6428571428573,147.57142857142858 1345.3214285714287,148.78571428571428 1440,150 L 1440,400 L 0,400 Z;
        M 0,400 L 0,150 C 119.75,139.03571428571428 239.5,128.07142857142858 358,135 C 476.5,141.92857142857142 593.75,166.75 716,158 C 838.25,149.25 965.5,106.92857142857142 1087,100 C 1208.5,93.07142857142858 1324.25,121.53571428571429 1440,150 L 1440,400 L 0,400 Z"
          />
        </path>
      </svg>
    </div>
  );
};
