import { EIndicatorTheme } from '@shared/types/ui.type';

interface Props {
  className?: string;
  theme: EIndicatorTheme;
}

export const Indicator = ({ className, theme }: Props) => {
  const getColor = (theme: EIndicatorTheme) => {
    switch (theme) {
      case EIndicatorTheme.init:
        return ['bg-[#ffffff]', 'bg-[#ffffff]/30'];
      case EIndicatorTheme.good:
        return ['bg-[#00ff00]', 'bg-[#00ff00]/30'];
      case EIndicatorTheme.warn:
        return ['bg-[#fff200]', 'bg-[#fff200]/30'];
      case EIndicatorTheme.lowest:
        return ['bg-[#ad1e1e]', 'bg-[#ad1e1e]/30'];
      case EIndicatorTheme.caution:
        return ['bg-[#FDDEA1]', 'bg-[#FDDEA1]/30'];
    }
  };
  return (
    <div
      className={`w-[15px] h-[15px] rounded-2xl transition duration-500 relative flex justify-center items-center ${className ?? ''}`}
    >
      <div className={`w-[60%] h-[60%] rounded-2xl absolute animate-ping ${getColor(theme)[1]}`}></div>
      <div className={`w-[40%] h-[40%] rounded-2xl absolute ${getColor(theme)[0]}`}></div>
    </div>
  );
};
