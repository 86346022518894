import { Ref } from 'react';
import { useTranslation } from 'react-i18next';

import { CoinIcon } from '@shared/assets';
import { useUtils } from '@shared/hooks/utils/useUtils';

interface Props {
  prize: string;
  score: number;
  position: number;
  userName: string;
  measureRef?: Ref<HTMLDivElement>;
  isPurpure?: boolean;
  className?: string;
  scoreIcon: React.ReactNode;
}

export const WinnersMatchLeaderBoardDetail = ({
  position,
  prize,
  scoreIcon,
  score,
  userName,
  isPurpure,
  measureRef,
  className,
}: Props) => {
  const { t } = useTranslation();
  const { getLightweightUserName, getBalanceFormat } = useUtils();

  return (
    <div
      ref={measureRef}
      className={`h-[40px] w-full  rounded-20 flex items-center py-[5px] pr-[20px] ${prize ? 'pl-[14px]' : 'pl-[20px]'}  gap-x-[6px] ${isPurpure ? 'bg-primary-purpleDark' : 'bg-black/20'} ${className ?? ''}`}
    >
      <span className="text-white/50">{position}</span>
      <div className="flex-1 text-[12px]">@{getLightweightUserName(userName) ?? t('global:unknownUser')}</div>
      <div className="flex gap-x-[6px]">
        <div className="flex gap-x-[6px]">
          <span className="text-primary-purpleLight">{getBalanceFormat(score)}</span>
          {scoreIcon}
        </div>
        <div className="flex gap-x-[6px]">
          <span className="text-primary-purpleLight"> {getBalanceFormat(prize)}</span>
          <CoinIcon className="w-[18px] h-[20px]" />
        </div>
      </div>
    </div>
  );
};
