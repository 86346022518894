import { MAX_CATCHES_PER_PLAYER, MultiplayerNomination } from '@hf/shared-common';

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  IFinalLeaderBoard,
  IFinalLeaderBoardItem,
  ILastCoefficient,
  IMatchLifeTable,
  IMatchLifeTableItem,
} from '@shared/types/games.type';
import { EIndicatorTheme } from '@shared/types/ui.type';

export enum EMatchModals {
  choosingBet = 'choosingBet',
  waitingRoom = 'waitingRoom',
  exitMatch = 'exitMatch',
  confirmationRound = 'confirmationRound',
  cancelledMatch = 'cancelledMatch',
}
export enum EMatchButtons {
  returnHome = 'returnHome',
  findLobby = 'findLobby',
  choosingBet = 'choosingBet',
  joinRoom = 'joinRoom',
  disconnect = 'disconnect',
  useBaits = 'useBaits',
}

export interface FastMatchState {
  modals: Record<EMatchModals, boolean>;
  buttons: Record<EMatchButtons, boolean>;
  loadingButtons: Record<EMatchButtons, boolean>;
  bet: number;
  currency: number;
  waitTimeRoom: number;
  waitTimeNextRound: number;
  isShowInfoMatch: boolean;
  isShowHeaderInfoTournament: boolean;
  isShowLobbyButtons: boolean;
  countUsersWaitingRoom: number;
  countUsersGameRoom: number;
  isJoinWaitingRoom: boolean;
  isJoinGameRoom: boolean;
  matchId: number;
  currentRound: number;
  remainingBaits: number;
  waitingRoundTime: number;
  lastCoefficients: ILastCoefficient[];
  lifeTable: {
    list: IMatchLifeTable;
    me: IMatchLifeTable<IMatchLifeTableItem>;
  };
  finalLeaderBoard: {
    list: IFinalLeaderBoard;
    user: IFinalLeaderBoard<IFinalLeaderBoardItem>;
    win: string;
  };
  ping: { ms: number; theme: EIndicatorTheme };
}
const initState: FastMatchState = {
  modals: {
    [EMatchModals.choosingBet]: false,
    [EMatchModals.waitingRoom]: false,
    [EMatchModals.exitMatch]: false,
    [EMatchModals.confirmationRound]: false,
    [EMatchModals.cancelledMatch]: false,
  },
  buttons: {
    [EMatchButtons.returnHome]: false,
    [EMatchButtons.findLobby]: false,
    [EMatchButtons.choosingBet]: false,
    [EMatchButtons.joinRoom]: false,
    [EMatchButtons.disconnect]: false,
    [EMatchButtons.useBaits]: false,
  },
  loadingButtons: {
    [EMatchButtons.returnHome]: false,
    [EMatchButtons.findLobby]: false,
    [EMatchButtons.choosingBet]: false,
    [EMatchButtons.joinRoom]: false,
    [EMatchButtons.disconnect]: false,
    [EMatchButtons.useBaits]: false,
  },
  bet: 0,
  currency: 0,
  waitTimeRoom: 0,
  isShowInfoMatch: false,
  isShowHeaderInfoTournament: false,
  isShowLobbyButtons: false,
  countUsersWaitingRoom: 0,
  countUsersGameRoom: 0,
  isJoinWaitingRoom: false,
  isJoinGameRoom: false,
  matchId: 0,
  currentRound: 0,
  remainingBaits: MAX_CATCHES_PER_PLAYER,
  waitingRoundTime: 0,
  waitTimeNextRound: 0,
  lastCoefficients: [],
  lifeTable: {
    list: {
      [MultiplayerNomination.SUM_WEIGHT]: [],
      [MultiplayerNomination.QUANTITY]: [],
      [MultiplayerNomination.WEIGHT]: [],
    },
    me: {
      [MultiplayerNomination.SUM_WEIGHT]: { userId: 0, place: 0, userName: '', score: 0, placeIncreased: false },
      [MultiplayerNomination.QUANTITY]: { userId: 0, place: 0, userName: '', score: 0, placeIncreased: false },
      [MultiplayerNomination.WEIGHT]: { userId: 0, place: 0, userName: '', score: 0, placeIncreased: false },
    },
  },
  finalLeaderBoard: {
    list: {
      [MultiplayerNomination.SUM_WEIGHT]: [],
      [MultiplayerNomination.QUANTITY]: [],
      [MultiplayerNomination.WEIGHT]: [],
    },
    user: {
      [MultiplayerNomination.SUM_WEIGHT]: { score: 0, position: 0, prize: '', userName: '', userId: 0 },
      [MultiplayerNomination.QUANTITY]: { score: 0, position: 0, prize: '', userName: '', userId: 0 },
      [MultiplayerNomination.WEIGHT]: { score: 0, position: 0, prize: '', userName: '', userId: 0 },
    },
    win: '0',
  },
  ping: { ms: 0, theme: EIndicatorTheme.init },
};

export const matchSlice = createSlice({
  name: 'matchSlice',
  initialState: initState,
  selectors: {
    selectViewMatchModal: createSelector(
      (state: FastMatchState) => state.modals,
      (_: FastMatchState, modal: EMatchModals) => modal,
      (modals, modal) => modals[modal],
    ),
    selectDisabledMatchButton: createSelector(
      (state: FastMatchState) => state.buttons,
      (_: FastMatchState, button: EMatchButtons) => button,
      (buttons, button) => buttons[button],
    ),
    selectLoadingMatchButton: createSelector(
      (state: FastMatchState) => state.loadingButtons,
      (_: FastMatchState, button: EMatchButtons) => button,
      (loadingButtons, button) => loadingButtons[button],
    ),
    selectMatchBet: (state: FastMatchState) => state.bet,
    selectMatchCurrency: (state: FastMatchState) => state.currency,
    selectWaitTimeRoom: (state: FastMatchState) => state.waitTimeRoom,
    selectIsShowInfoMatch: (state: FastMatchState) => state.isShowInfoMatch,
    selectIsShowHeaderInfoTournament: (state: FastMatchState) => state.isShowHeaderInfoTournament,
    selectIsShowLobbyButtons: (state: FastMatchState) => state.isShowLobbyButtons,
    selectCountUsersWaitingRoom: (state: FastMatchState) => state.countUsersWaitingRoom,
    selectJoinWaitingRoom: (state: FastMatchState) => state.isJoinWaitingRoom,
    selectMatchId: (state: FastMatchState) => state.matchId,
    selectCurrentRound: (state: FastMatchState) => state.currentRound,
    selectRemainingBaits: (state: FastMatchState) => state.remainingBaits,
    selectJoinGameRoom: (state: FastMatchState) => state.isJoinGameRoom,
    selectCountUsersGameRoom: (state: FastMatchState) => state.countUsersGameRoom,
    selectWaitTimeNextRound: (state: FastMatchState) => state.waitTimeNextRound,
    selectLastCoefficients: (state: FastMatchState) => state.lastCoefficients,
    selectLifeTable: (state: FastMatchState) => state.lifeTable,
    selectFinalLeaderBoard: (state: FastMatchState) => state.finalLeaderBoard,
    selectFastMatchPing: (state: FastMatchState) => state.ping,
  },
  reducers: {
    setViewMatchModals(state, action: PayloadAction<{ modal: EMatchModals; value: boolean }>) {
      const { modal, value } = action.payload;
      state.modals[modal] = value;
    },
    setDisabledMatchButtons(state, action: PayloadAction<{ button: EMatchButtons; value: boolean }>) {
      const { button, value } = action.payload;
      state.buttons[button] = value;
    },
    setLoadingMatchButtons(state, action: PayloadAction<{ button: EMatchButtons; value: boolean }>) {
      const { button, value } = action.payload;
      state.loadingButtons[button] = value;
    },
    setMatchBet(state, action: PayloadAction<number>) {
      state.bet = action.payload;
    },
    setMatchCurrency(state, action: PayloadAction<number>) {
      state.currency = action.payload;
    },
    setWaitTimeRoom(state, action: PayloadAction<number>) {
      state.waitTimeRoom = action.payload;
    },
    setIsShowInfoMatch(state, action: PayloadAction<boolean>) {
      state.isShowInfoMatch = action.payload;
    },
    setIsShowHeaderInfoTournament(state, action: PayloadAction<boolean>) {
      state.isShowHeaderInfoTournament = action.payload;
    },
    setIsShowLobbyButtons(state, action: PayloadAction<boolean>) {
      state.isShowLobbyButtons = action.payload;
    },
    setCountUsersWaitingRoom(state, action: PayloadAction<number>) {
      state.countUsersWaitingRoom = action.payload;
    },
    setJoinWaitingRoom(state, action: PayloadAction<boolean>) {
      state.isJoinWaitingRoom = action.payload;
    },
    setMatchId(state, action: PayloadAction<number>) {
      state.matchId = action.payload;
    },
    setCurrentRound(state, action: PayloadAction<number>) {
      state.currentRound = action.payload;
    },
    setRemainingBaits(state, action: PayloadAction<number>) {
      state.remainingBaits = action.payload;
    },
    setJoinGameRoom(state, action: PayloadAction<boolean>) {
      state.isJoinGameRoom = action.payload;
    },
    setCountUsersGameRoom(state, action: PayloadAction<number>) {
      state.countUsersGameRoom = action.payload;
    },
    setWaitTimeNextRound(state, action: PayloadAction<number>) {
      state.waitTimeNextRound = action.payload;
    },
    setLastCoefficient(state, action: PayloadAction<ILastCoefficient>) {
      state.lastCoefficients.unshift(action.payload);
    },
    restoreLastCoefficients(state, action: PayloadAction<ILastCoefficient[]>) {
      state.lastCoefficients = action.payload;
    },
    setLifeTable(state, action: PayloadAction<{ list: IMatchLifeTable; me: IMatchLifeTable<IMatchLifeTableItem> }>) {
      state.lifeTable = action.payload;
    },
    setFinalLeaderBoard(
      state,
      action: PayloadAction<{ list: IFinalLeaderBoard; user: IFinalLeaderBoard<IFinalLeaderBoardItem>; win: string }>,
    ) {
      state.finalLeaderBoard = action.payload;
    },
    resetFinalLeaderBoard(state) {
      state.finalLeaderBoard = initState.finalLeaderBoard;
    },
    resetLastCoefficients(state) {
      state.lastCoefficients = [];
    },
    resetLifeTable(state) {
      state.lifeTable = initState.lifeTable;
    },
    setFastMatchPing(state, action: PayloadAction<{ ms: number; theme: EIndicatorTheme }>) {
      state.ping = action.payload;
    },
  },
});

export const {
  setViewMatchModals,
  setDisabledMatchButtons,
  setLoadingMatchButtons,
  setMatchBet,
  setMatchCurrency,
  setWaitTimeRoom,
  setIsShowInfoMatch,
  setIsShowHeaderInfoTournament,
  setIsShowLobbyButtons,
  setCountUsersWaitingRoom,
  setCountUsersGameRoom,
  setJoinWaitingRoom,
  setMatchId,
  setCurrentRound,
  setRemainingBaits,
  setJoinGameRoom,
  setWaitTimeNextRound,
  setLastCoefficient,
  setLifeTable,
  resetLastCoefficients,
  resetLifeTable,
  restoreLastCoefficients,
  resetFinalLeaderBoard,
  setFinalLeaderBoard,
  setFastMatchPing,
} = matchSlice.actions;

export const {
  selectRemainingBaits,
  selectIsShowInfoMatch,
  selectViewMatchModal,
  selectDisabledMatchButton,
  selectLoadingMatchButton,
  selectMatchBet,
  selectMatchCurrency,
  selectWaitTimeRoom,
  selectIsShowHeaderInfoTournament,
  selectIsShowLobbyButtons,
  selectCountUsersWaitingRoom,
  selectCountUsersGameRoom,
  selectJoinWaitingRoom,
  selectMatchId,
  selectCurrentRound,
  selectJoinGameRoom,
  selectWaitTimeNextRound,
  selectLastCoefficients,
  selectFinalLeaderBoard,
  selectLifeTable,
  selectFastMatchPing,
} = matchSlice.selectors;
