import { Ref } from 'react';
import { useTranslation } from 'react-i18next';

import { LeaderBoardConfig } from '@pages/leaderBoard/configs/LeaderBoard.config';
import { CoinIcon } from '@shared/assets';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { ELeaderBoardPrizePlace } from '@shared/types/leaderBoard.type';

interface Props {
  prize?: ELeaderBoardPrizePlace;
  position: number;
  userName: string;
  balance: string;
  measureRef?: Ref<HTMLDivElement>;
  isPurpure?: boolean;
  className?: string;
}

export const LeaderBoardListDetail = ({
  position,
  prize,
  userName,
  balance,
  isPurpure,
  measureRef,
  className,
}: Props) => {
  const { t } = useTranslation();
  const { prizeLeaderBoard } = LeaderBoardConfig();
  const { getLightweightUserName, getBalanceFormat } = useUtils();
  return (
    <div
      ref={measureRef}
      className={`h-[30px] w-full  rounded-20 flex items-center py-[5px] pr-[20px] ${prize ? 'pl-[14px]' : 'pl-[20px]'}  gap-x-[6px] ${isPurpure ? 'bg-primary-purpleDark' : 'bg-black/20'} ${className ?? ''}`}
    >
      <span className="text-white/50">{prize ? prizeLeaderBoard[prize] : position}</span>
      <div className="flex-1">@{getLightweightUserName(userName) ?? t('global:unknownUser')}</div>
      <div className="flex gap-x-[6px]">
        <span className="text-primary-purpleLight">{getBalanceFormat(balance)}</span>
        <CoinIcon className="w-[18px] h-[20px]" />
      </div>
    </div>
  );
};
