import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { animated, useSpringRef } from 'react-spring';
import Footer from 'src/layouts/BaseLayout/widgets/Footer';
import { GameHeader } from 'src/layouts/GameLayout/widgets/GameHeader/GameHeader';
import { ProducerCore, ScreenId } from 'src/pixi/ProducerCore';
import BackgroundScreen from 'src/pixi/screens/BackgroundScreen';
import LoadingScreen from 'src/pixi/screens/LoadingScreen';

import { EAppRouterUrl } from '@app/AppRouter';
import { leaderBoardApi } from '@shared/api/leaderBoard';
import { backgroundsAssets } from '@shared/configs/assets.config';
import { useAnimateSpring } from '@shared/hooks/integration/useAnimateSpring';
import { useLeaderBoard } from '@shared/hooks/useLeaderBoard';
import { useWebApp } from '@shared/providers/WebAppProvider';
import { selectShowFooter, selectShowGameHeader } from '@shared/store/slice/CommonSlice';
import { EFishingResultModals, selectFishingWin, selectViewFishingResultModal } from '@shared/store/slice/FishingSlice';
import { useAppSelector } from '@shared/store/store';
import { EHeaderColor } from '@shared/types/global.types';
import { Video } from '@shared/ui';

export const GameLayout = () => {
  const { webApp } = useWebApp();

  const coreContainer = useRef(null);
  const [isViewContent, setIsViewContent] = useState(false);
  const gameContent = useRef<HTMLDivElement | null>(null);
  const isGameInitialized = useRef<boolean>(false);
  const location = useLocation();
  const backgroundSn = useRef<BackgroundScreen | null>(null);

  const { useAnimTranslateY } = useAnimateSpring();
  const { initCacheTime, checkCache, isInitCacheTime } = useLeaderBoard();

  const isShowHeader = useAppSelector(selectShowGameHeader);
  const isShowFooter = useAppSelector(selectShowFooter);
  const viewFishGone = useAppSelector((state) => selectViewFishingResultModal(state, EFishingResultModals.fisGone));
  const viewYourCatch = useAppSelector((state) => selectViewFishingResultModal(state, EFishingResultModals.yourCatch));
  const fishingWin = useAppSelector(selectFishingWin);

  const isFullScreen = [EAppRouterUrl.fishing, EAppRouterUrl.fastMatch, EAppRouterUrl.winnersMatch].some((route) =>
    location.pathname.startsWith(route),
  );
  const isSkipLoading = [EAppRouterUrl.winnersMatch].some((route) => location.pathname.startsWith(route));

  const { data: leaderBoardMe, refetch: refetchMe } = leaderBoardApi.useLeaderboardMeQuery({});

  const headerAnimationRef = useSpringRef();

  const headerAnimation = useAnimTranslateY(isShowHeader, { animRef: headerAnimationRef });
  const footerAnimation = useAnimTranslateY(isShowFooter, { yStart: '0', yEnd: '+50%' });

  useEffect(() => {
    if (!coreContainer.current || isGameInitialized.current) return;
    ProducerCore.bindCanvas(coreContainer.current);

    ProducerCore.getApp && ProducerCore.addScreen(new BackgroundScreen(ProducerCore.getApp), 0);
    ProducerCore.getApp &&
      ProducerCore.addScreen(new LoadingScreen(ProducerCore.getApp, { skipShowLoading: isSkipLoading }), 5);

    backgroundSn.current = ProducerCore.getScreen<BackgroundScreen>(ScreenId.Background);
    isGameInitialized.current = true;
    setIsViewContent(true);
  }, [coreContainer.current]);

  useEffect(() => {
    if (!gameContent.current) return;
    const top = 'top-[90px]';

    isShowHeader ? gameContent.current.classList.add(top) : gameContent.current.classList.remove(top);
    headerAnimationRef.start();
  }, [isShowHeader]);

  useEffect(() => {
    const isUpdate = checkCache();
    isUpdate && refetchMe();

    webApp?.setHeaderColor(EHeaderColor.dubai);
    return () => {
      ProducerCore.removeScreen(ScreenId.Background);
      ProducerCore.removeScreen(ScreenId.Loading);
      ProducerCore.destroyed();
      webApp?.setHeaderColor(EHeaderColor.base);
    };
  }, []);

  useEffect(() => {
    if (!leaderBoardMe?.userData) return;

    !isInitCacheTime && initCacheTime();
  }, [leaderBoardMe]);

  const gameContentClass = `${isFullScreen ? `bottom-0 ${isShowFooter ? 'pb-[75px]' : 'mb-[25px]'}` : ''}`;
  return (
    <>
      <div className={`h-dvh bg-no-repeat bg-cover bg-center`}>
        <div className={`relative flex flex-col h-full  overflow-y-auto overflow-x-hidden`}>
          <div className="absolute w-full h-full overflow-hidden z-0">
            <Video
              className="!h-full"
              classNameVideo="!h-full object-cover object-[top_center]"
              isLoader={false}
              src={backgroundsAssets.urls.backgroundMoscowNewYearVideo}
              onLoadedData={() => {
                backgroundSn.current?.hidden();
              }}
            />
          </div>
          <div ref={coreContainer} className="coreContainer w-full h-full overflow-hidden z-1"></div>

          <animated.div style={headerAnimation} className="absolute full-absolute h-[80px] p-[12px_8px] z-20">
            <GameHeader
              fishGone={viewFishGone}
              win={viewYourCatch}
              rewardAmount={fishingWin?.rewardAmount ?? 0}
              place={leaderBoardMe?.userData?.position ?? 0}
            />
          </animated.div>
          <div
            ref={gameContent}
            className={`absolute top-0 w-full flex flex-col px-[12px] z-2 ${gameContentClass}  transition-all`}
          >
            {isViewContent && <Outlet />}
          </div>
        </div>
        <animated.div
          style={footerAnimation}
          className="w-full flex justify-center items-center h-[70px] fixed bottom-0 z-3"
        >
          <Footer className={`px-4 h-[55px] mb-1 self-center bg-secondary-darkBlue/80`} />
        </animated.div>
      </div>
    </>
  );
};
