import { t } from 'i18next';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  AmplitudeEventClient,
  FISHING_BAITS_PER_DAY,
  FISHING_LOOSE_COINS,
  IClaimLoseBonusFishing,
} from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { EAppRouterUrl } from '@app/AppRouter';
import { IApiError } from '@shared/api/api';
import { fishingAPI } from '@shared/api/fishing';
import { CoinIcon, ComeTomorrowIllustration, FishingCompletedIllustration } from '@shared/assets';
import { ESamples } from '@shared/hooks/integration/useAudio';
import { useUtils } from '@shared/hooks/utils/useUtils';
import {
  EFishingModals,
  selectFishingInfo,
  selectViewFishingModal,
  setFishingInfo,
  setViewFishingModals,
} from '@shared/store/slice/FishingSlice';
import { selectUserProfile, setUserProfile } from '@shared/store/slice/UserSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { Button, Wrapper } from '@shared/ui';
import { Modal } from '@shared/widgets';

export const FishingModals = () => {
  const dispatch = useAppDispatch();
  const fishingInfo = useAppSelector(selectFishingInfo);
  const navigate = useNavigate();
  const [fishingLoseBonus] = fishingAPI.useFishingLoseBonusMutation();
  const userProfile = useAppSelector(selectUserProfile);
  const [loading, setLoading] = useState(false);
  const { toClick } = useUtils();
  const viewModalComeTomorrow = useAppSelector((state) => selectViewFishingModal(state, EFishingModals.comeTomorrow));
  const viewModalCatchToDay = useAppSelector((state) => selectViewFishingModal(state, EFishingModals.catchToDay));

  const toggleModal = (modal: EFishingModals, value: boolean) => {
    dispatch(setViewFishingModals({ modal, value }));
  };
  const loseBonus = FISHING_LOOSE_COINS[FISHING_BAITS_PER_DAY];
  const getBonus = () => {
    setLoading(true);
    fishingLoseBonus()
      .unwrap()
      .then((data: IClaimLoseBonusFishing) => {
        userProfile && dispatch(setUserProfile({ ...userProfile, balance: data.newBalance }));
        fishingInfo && dispatch(setFishingInfo({ ...fishingInfo, isClaimLoseBonus: true }));
      })
      .catch(({ data }: { data: IApiError }) => {
        console.error(`Error claiming lose bonus: ${data?.message}`);
        if (data) toast.error(data.message);
      })
      .finally(() => {
        setLoading(false);
        toggleModal(EFishingModals.comeTomorrow, !viewModalComeTomorrow);
      });
  };

  const actionToClick = () => {
    toggleModal(EFishingModals.comeTomorrow, false);
    toggleModal(EFishingModals.catchToDay, false);
  };
  return (
    <>
      <Modal
        isOpen={viewModalComeTomorrow}
        title={t('fishing:modal.comeTomorrow.title')}
        onClose={() => toggleModal(EFishingModals.comeTomorrow, !viewModalComeTomorrow)}
        className="flex flex-col gap-[10px]"
      >
        <Wrapper className="flex flex-col gap-y-2 items-center">
          <ComeTomorrowIllustration className="w-10/12 h-auto" />
          <span className="text-[18px] text-center">
            {t('fishing:modal.comeTomorrow.description', { count: loseBonus })}
          </span>
        </Wrapper>
        <Button
          size="lg"
          disabled={loading}
          loading={loading}
          shine={true}
          sound={{ samples: ESamples.buttonConfirm, disabled: loading }}
          vibrationOpt={{ func: 'notificationOccurred', style: 'success' }}
          onClick={getBonus}
        >
          {t('global:getBonus')}{' '}
        </Button>
        <NavLink
          to={EAppRouterUrl.tasks}
          onClick={(event) =>
            toClick(event, EAppRouterUrl.tasks, {
              action: () => {
                amplitude.track(AmplitudeEventClient.MoreTriesOpened);
                actionToClick();
              },
            })
          }
        >
          <Button size="lg" sound={{ disabled: true }} vibrationOpt={undefined}>
            {t('fishing:modal.comeTomorrow.button.getTries')}
          </Button>
        </NavLink>
        <NavLink
          to={EAppRouterUrl.leaderboard}
          onClick={(event) => toClick(event, EAppRouterUrl.leaderboard, { action: actionToClick })}
        >
          <Button size="lg" theme="gray" sound={{ disabled: true }} vibrationOpt={undefined}>
            {t('fishing:modal.comeTomorrow.button.leaderboard')}
          </Button>
        </NavLink>
      </Modal>

      <Modal
        isOpen={viewModalCatchToDay}
        title={t('fishing:modal.catchDay.title')}
        onClose={() => toggleModal(EFishingModals.catchToDay, !viewModalCatchToDay)}
        className="flex flex-col gap-[10px]"
      >
        <Wrapper className="flex flex-col gap-y-2 items-center">
          <FishingCompletedIllustration className="w-8/12 h-auto" />
          <span className="text-[18px] text-center font-bold">{t('fishing:modal.catchDay.reward')}</span>
          <div className="flex gap-x-2 items-center justify-center text-primary-purpleLight text-[32px] font-bold  after:bg-claimed-points after:z-[-1] after:absolute after:w-3/4 after:h-[11px] after:rounded-full before:z-[-1] before:bg-claimed-points before:absolute before:w-3/4 before:h-[44px] before:rounded-full">
            <CoinIcon className="w-[32px] h-[36px]" />
            <span>{+(fishingInfo?.coinsWin ?? 0) + +(fishingInfo?.coinsBonusWin ?? 0)}</span>
          </div>
        </Wrapper>
        <NavLink
          to={EAppRouterUrl.tasks}
          onClick={(event) => toClick(event, EAppRouterUrl.tasks, { action: actionToClick })}
        >
          <Button size="lg" sound={{ disabled: true }} vibrationOpt={undefined}>
            {t('fishing:modal.catchDay.button.moreBaits')}
          </Button>
        </NavLink>
        <NavLink
          to={EAppRouterUrl.leaderboard}
          onClick={(event) => toClick(event, EAppRouterUrl.leaderboard, { action: actionToClick })}
        >
          <Button size="lg" theme="gray" sound={{ disabled: true }} vibrationOpt={undefined}>
            {t('fishing:modal.catchDay.button.leaderboard')}
          </Button>
        </NavLink>
      </Modal>
    </>
  );
};
