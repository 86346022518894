export interface ITextSliderOption {
  value: number;
  label: string | React.ReactNode;
  className: string;
  titleClassName: string;
}
export type ITextSliderOptions = Record<number, ITextSliderOption>;

export enum EIndicatorTheme {
  init = 'init',
  good = 'good',
  warn = 'warn',
  lowest = 'lowest',
  caution = 'caution',
}
