import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { EAppRouterUrl } from '@app/AppRouter';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { Button } from '@shared/ui';

interface Props {
  className?: string;
}
export const LeaderBoardBtn = ({ className }: Props) => {
  const { t } = useTranslation();
  const { toClick } = useUtils();
  return (
    <div className={`flex flex-col gap-y-[10px] ${className ?? ''}`}>
      <NavLink to={EAppRouterUrl.tasks} onClick={(event) => toClick(event, EAppRouterUrl.tasks)}>
        <Button size="lg" shine={true} sound={{ disabled: true }} vibrationOpt={undefined}>
          {t('leaderboard:league.button.getTries')}
        </Button>
      </NavLink>
    </div>
  );
};
