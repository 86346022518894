import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AmplitudeEventClient,
  IUserTonWallet,
  WALLET_CONNECT_REWARD_BAITS,
  WALLET_CONNECT_REWARD_COINS,
} from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { BaitsIcon, CoinIcon, TonIcon } from '@shared/assets';
import { useWallet } from '@shared/hooks/integration/useWallet';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { ETaskCustomType } from '@shared/types/taskList.types';
import { Button, Wrapper } from '@shared/ui';
import { Modal } from '@shared/widgets/Modal/Modal';

interface Props {
  userWallet?: IUserTonWallet;
  setAvailableTaskCount: Dispatch<SetStateAction<number>>;
}

export const TaskCardWallet = ({ userWallet, setAvailableTaskCount }: Props) => {
  const { t } = useTranslation();
  const { updateProfileCoins, updateFishingBaits, updateUserWallet, deleteUserWallet } = useUtils();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteWallet, setIsDeleteWallet] = useState(false);

  const coins = WALLET_CONNECT_REWARD_COINS;
  const baits = WALLET_CONNECT_REWARD_BAITS;

  const onRewardWallet = useCallback((wallet: IUserTonWallet) => {
    amplitude.track(AmplitudeEventClient.UserRewarded, {
      task_type: ETaskCustomType.TON_CONNECT_WALLETS,
      added_tries: baits,
      reward_size: coins,
      content: 'Connect wallet',
    });

    setAvailableTaskCount((prev) => prev - 1);
    updateProfileCoins(coins);
    updateFishingBaits(null, baits);
    updateUserWallet(wallet);
  }, []);

  const { openTonWalletModal, disconnectWallet, completedTask } = useWallet({
    autoSendConnectServer: true,
    onReward: onRewardWallet,
  });

  const handleClick = () => {
    if (completedTask && userWallet) {
      setIsModalOpen(true);
      return;
    }

    amplitude.track(AmplitudeEventClient.WalletConnectStarted);
    amplitude.track(AmplitudeEventClient.TaskStarted, { task_type: 'Connect wallet' });
    openTonWalletModal();
  };

  const handleDisconnect = () => {
    setIsDeleteWallet(true);

    disconnectWallet();
    deleteUserWallet();

    setIsDeleteWallet(false);
    setIsModalOpen(false);
  };

  return (
    <>
      <Wrapper paddingSize="sm">
        <li className="w-full flex items-center justify-center px-[5px] py-[3px] gap-[10px] bg-blue-dark rounded-[10px]">
          <div className="flex-1 flex gap-[6px]">
            <div className="flex items-center text-blue-light w-[1.25em] h-auto justify-center">
              <TonIcon className="w-5 h-5" />
            </div>
            <div className="flex-1 flex flex-col">
              <p className="font-bold text-[13px]">{t('task:list.tonConnect.text')}</p>
              {!completedTask && (
                <p className="text-[13px] flex items-center gap-x-1">
                  <span>+ {coins}</span>
                  <CoinIcon className="w-[14px] h-[14px]" />
                  <span>+ {baits}</span>
                  <BaitsIcon className="w-[14px] h-[14px]" />
                </p>
              )}
            </div>
          </div>
          <Button
            className={`w-24 ${userWallet?.isActive ? 'disabled:bg-sand-light/50 hover:bg-sand-dark' : ''}`}
            size="sm"
            onClick={handleClick}
            // disabled={completedTask}
          >
            {completedTask ? t('global:connected') : t('global:connect')}
          </Button>
        </li>
      </Wrapper>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title={t('task:modal.wallet.title')}>
        <div className="space-y-4">
          {userWallet && (
            <>
              <div className="bg-blue-darker p-2 rounded break-all">
                <p className="text-sm font-bold mb-1">{t('task:modal.wallet.address')}:</p>
                <p className="text-[10px]">{userWallet.tonAddress}</p>
                <p className="text-sm font-bold mt-2 mb-1">{t('task:modal.wallet.provider')}:</p>
                <p className="text-[10px]">{userWallet.appName}</p>
              </div>
              {/* <Button
                theme="red"
                loading={isDeleteWallet}
                disabled={isDeleteWallet}
                className="w-full"
                onClick={handleDisconnect}
              >
                {' '}
                {t('global:disconnect')}
              </Button> */}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
