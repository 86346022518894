import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import SlotCounter from 'react-slot-counter';
import { animated } from 'react-spring';

import { AmplitudeEventClient, QUICK_MATCH_MAX_PLAYERS } from '@hf/shared-common';

import * as amplitude from '@amplitude/analytics-browser';
import { EAppRouterUrl } from '@app/AppRouter';
import { MatchLeaveTournament } from '@pages/games/multiplayer/ui/MatchLeaveTournament';
import { ConfirmationRoundModal } from '@pages/games/multiplayer/widgets/ConfirmationRoundModal/ConfirmationRoundModal';
import { FastMatchChoosingBetModal } from '@pages/games/multiplayer/widgets/FastMatchChoosingBetModal/FastMatchChoosingBetModal';
import { FastMatchWaitingRoomModal } from '@pages/games/multiplayer/widgets/FastMatchWaitingRoomModal/FastMatchWaitingRoomModal';
import { MatchLastCoefficient } from '@pages/games/multiplayer/widgets/MatchLastCoefficient/MatchLastCoefficient';
import { RemainingBaitsIcon } from '@shared/assets';
import { useAnimateSpring } from '@shared/hooks/integration/useAnimateSpring';
import { useMatchActions } from '@shared/hooks/match/useMatchActions';
import { useMatchCore } from '@shared/hooks/match/useMatchCore';
import { useUtils } from '@shared/hooks/utils/useUtils';
import {
  selectGameButtonParams,
  selectGameButtonResults,
  selectShowGameButton,
  setShowGameHeader,
} from '@shared/store/slice/CommonSlice';
import {
  EMatchButtons,
  EMatchModals,
  selectCountUsersGameRoom,
  selectCurrentRound,
  selectDisabledMatchButton,
  selectFastMatchPing,
  selectIsShowHeaderInfoTournament,
  selectIsShowInfoMatch,
  selectIsShowLobbyButtons,
  selectLastCoefficients,
  selectLifeTable,
  selectLoadingMatchButton,
  selectMatchId,
  selectRemainingBaits,
  selectViewMatchModal,
  selectWaitTimeNextRound,
  setViewMatchModals,
} from '@shared/store/slice/MatchSlice';
import { useAppDispatch, useAppSelector } from '@shared/store/store';
import { Button, GameButton, Indicator, ProgressBar, Wrapper } from '@shared/ui';
import { GameButtonResult } from '@shared/ui/GameButton/GameButtonResult';
import { NBSP } from '@shared/utils/helpers.utils';

import { MatchLifeLeaderboard } from './widgets/MatchLifeLeaderboard/MatchLifeLeaderboard';

export const FastMatchPage = () => {
  const dispatch = useAppDispatch();

  const [progress, setProgress] = useState(100);
  const { toClick } = useUtils();
  const { t } = useTranslation();
  const { useAnimTranslateX, useAnimTranslateY } = useAnimateSpring();
  const {
    initGame,
    findLobby,
    confirmJoinRoom,
    destroyScene,
    processingGameButton,
    onDisconnectWaitingRoomHandler,
    leaveTournament,
    confirmNextRound,
  } = useMatchCore();
  const lifeTable = useAppSelector(selectLifeTable);
  const gameButtonParams = useAppSelector(selectGameButtonParams);
  const gameButtonResults = useAppSelector(selectGameButtonResults);
  const countUsersGameRoom = useAppSelector(selectCountUsersGameRoom);
  const currentRound = useAppSelector(selectCurrentRound);
  const waitTimeNextRound = useAppSelector(selectWaitTimeNextRound);
  const isShowInfo = useAppSelector(selectIsShowInfoMatch);
  const lastCoefficients = useAppSelector(selectLastCoefficients);
  const isShowHeaderInfoTournament = useAppSelector(selectIsShowHeaderInfoTournament);
  const isShowLobbyButtons = useAppSelector(selectIsShowLobbyButtons);
  const showButton = useAppSelector(selectShowGameButton);
  const remainingBaits = useAppSelector(selectRemainingBaits);
  const { closeFastMatchPage } = useMatchActions();
  const isDisabledReturnHome = useAppSelector((state) => selectDisabledMatchButton(state, EMatchButtons.returnHome));
  const isLoadingFindLobby = useAppSelector((state) => selectLoadingMatchButton(state, EMatchButtons.findLobby));
  const isDisabledFindLobby = useAppSelector((state) => selectDisabledMatchButton(state, EMatchButtons.findLobby));
  const showInfoTournamentStyle = useAnimTranslateY(isShowHeaderInfoTournament);
  const gameId = useAppSelector(selectMatchId);
  const ping = useAppSelector(selectFastMatchPing);
  const isOpenConfirmationRoundModal = useAppSelector((state) =>
    selectViewMatchModal(state, EMatchModals.confirmationRound),
  );

  const onFindLobbyHandlerHandler = () => {
    if (isDisabledFindLobby || isLoadingFindLobby) return;
    dispatch(setViewMatchModals({ modal: EMatchModals.choosingBet, value: true }));
  };

  const onLeaveTournamentHandler = () => {
    leaveTournament();
  };

  useEffect(() => {
    amplitude.track(AmplitudeEventClient.FastMultiplayerOpened);
    initGame();
    return () => {
      destroyScene();
      closeFastMatchPage();
    };
  }, []);

  useEffect(() => {
    if (!waitTimeNextRound) return;

    const currentTime = Date.now();
    const totalDuration = waitTimeNextRound - currentTime;

    if (totalDuration <= 0) {
      setProgress(0);
      return;
    }
    const steps = 100;
    let stepProgress = 100;

    const intervalTime = totalDuration / steps;

    const intervalId = setInterval(() => {
      stepProgress--;
      setProgress(stepProgress);
      if (stepProgress <= 0) {
        clearInterval(intervalId);
      }
    }, intervalTime);

    return () => clearInterval(intervalId);
  }, [waitTimeNextRound]);

  useEffect(() => {
    if (!isOpenConfirmationRoundModal) setProgress(100);
  }, [isOpenConfirmationRoundModal]);

  return (
    <>
      <div className="flex h-full w-full flex-col gap-y-[10px] relative overflow-hidden">
        {isShowHeaderInfoTournament && (
          <animated.div
            style={showInfoTournamentStyle}
            className={`w-full flex flex-col items-center gap-y-[5px] pt-[5px]`}
          >
            <div className="flex justify-between w-full text-[10px]">
              <span>Game id: {gameId}</span>
              <div className="flex items-center">
                <Indicator className="inline-flex" theme={ping.theme} />
                <SlotCounter value={ping.ms} sequentialAnimationMode useMonospaceWidth autoAnimationStart={false} />
                {NBSP}ms
              </div>
            </div>
            <MatchLeaveTournament onClick={onLeaveTournamentHandler} />
            <MatchLastCoefficient list={lastCoefficients} />
            <MatchLifeLeaderboard
              playersReady={{ current: countUsersGameRoom, max: QUICK_MATCH_MAX_PLAYERS }}
              roundLeft={currentRound}
              list={lifeTable.list}
              me={lifeTable.me}
            />
          </animated.div>
        )}

        <animated.div style={useAnimTranslateX(isShowInfo)} className="flex flex-col gap-y-[10px]">
          <Wrapper title={t('multiplayer:fastMatch.title')} theme="darkBlue" />
          <Wrapper className="flex flex-col gap-y-[20px]" theme="darkBlue">
            <div className="flex flex-col gap-y-[10px] px-[14px]">
              <span>{t('multiplayer:fastMatch.howToPlay.title')}</span>
              <ul className="list-disc">
                <li>{t('multiplayer:fastMatch.howToPlay.li_1')}</li>
                <li>
                  {t('multiplayer:fastMatch.howToPlay.li_2')}
                  <ul className="list-dash pl-2">
                    <li>
                      {NBSP} {t('multiplayer:fastMatch.howToPlay.li_2_1')}
                    </li>
                    <li>
                      {NBSP} {t('multiplayer:fastMatch.howToPlay.li_2_2')}
                    </li>
                    <li>
                      {NBSP} {t('multiplayer:fastMatch.howToPlay.li_2_3')}
                    </li>
                  </ul>
                </li>
                <li>{t('multiplayer:fastMatch.howToPlay.li_3')}</li>
              </ul>
            </div>
          </Wrapper>
        </animated.div>
        <animated.div
          style={useAnimTranslateY(isShowLobbyButtons)}
          className="h-full flex flex-col gap-y-[10px] justify-end"
        >
          <Button
            size="lg"
            onClick={onFindLobbyHandlerHandler}
            loading={isLoadingFindLobby}
            disabled={isDisabledFindLobby}
            sound={{ disabled: true }}
          >
            {t('global:findLobby')}
          </Button>
          <NavLink
            to={EAppRouterUrl.fishing}
            onClick={(event) =>
              toClick(event, EAppRouterUrl.fishing, {
                disabled: isDisabledReturnHome,
                action: () => {
                  dispatch(setShowGameHeader(false));
                },
              })
            }
          >
            <Button theme="gray" className="w-full" disabled={isDisabledReturnHome} sound={{ disabled: true }}>
              {t('global:returnHome')}
            </Button>
          </NavLink>
        </animated.div>

        {showButton && (
          <div className="fixed  bottom-[25px] left-0 right-0 mx-auto flex flex-col items-center">
            <ProgressBar
              progress={progress}
              className={`mb-[10px] max-w-[200px] h-[10px] transition-opacity ${!waitTimeNextRound ? 'opacity-0' : ''}`}
              classNameContent="bg-primary-blueExtraDark"
              transition=""
            />
            <GameButton
              pulse={gameButtonParams.pulse}
              view={showButton}
              mode={gameButtonParams.mode}
              text={gameButtonParams.text}
              disabled={gameButtonParams.disabled}
              loading={gameButtonParams.loading}
              showExtraContent={gameButtonResults.show}
              onClick={processingGameButton}
            >
              <GameButtonResult
                yourCatch={gameButtonResults.catch}
                coins={gameButtonResults.coins}
                fishes={gameButtonResults.fishes}
              />
            </GameButton>
            <div>
              <span className="text-white font-bold text-[26px] flex items-center justify-center gap-x-[5px]">
                <RemainingBaitsIcon width={26} height={26} />
                <span>{remainingBaits}</span>
              </span>
            </div>
          </div>
        )}
      </div>
      <FastMatchChoosingBetModal confirm={findLobby} />
      <FastMatchWaitingRoomModal confirm={confirmJoinRoom} disconnect={onDisconnectWaitingRoomHandler} />
      <ConfirmationRoundModal
        isOpen={isOpenConfirmationRoundModal}
        confirmNextRound={confirmNextRound}
        showProgress={true}
        progress={progress}
      />
    </>
  );
};
