import { gsap } from 'gsap';
import { BitmapText, Container } from 'pixi.js';

interface IComponentCounterConfig {
  gapX: number; // Расстояние между цифрами
  defaultScale: { x: number; y: number };
  defaultShow: number;
  fontName: string;
  size: number;
  tint: { [key: number]: string };
}
const defaultConfig: IComponentCounterConfig = {
  gapX: 70,
  defaultScale: { x: 0, y: 0 },
  defaultShow: 0,
  fontName: 'Inter Bold',
  size: 130,
  tint: {
    1: '0xffffff',
    2: '0xe9d2ff',
    3: '0xd4aeff',
    4: '0xc18fff',
    5: '0xa669ff',
    6: '0x5f00ff',
  },
};

export default class UiCounterNew {
  private _view: Container;
  private readonly _config: IComponentCounterConfig;
  private _isDestroyed: boolean = false;
  private _poolDigits: Map<number, BitmapText> = new Map();
  private _currentDigits: string[] = ['0', '0', '.', '0', '0'];
  private _interval: number = 0;

  get view(): Container {
    return this._view;
  }
  constructor() {
    this._config = { ...defaultConfig };
    this._view = new Container();
    this._view.name = 'UiCounterNew';
    this.initialize();
  }

  private initialize(): void {
    if (this._isDestroyed) return;

    try {
      this.initCounter();
      this.adaptive();
    } catch (error) {
      console.error('Failed to initialize counter:', error);
    }
  }

  private initCounter(): void {
    if (this._isDestroyed) return;
    let accWeight = 0;
    try {
      for (let i = 0; i < this._currentDigits.length; i++) {
        const bitmapText = new BitmapText(this._currentDigits[i], {
          fontName: this._config.fontName,
          fontSize: this._config.size,
          align: 'center',
        });

        bitmapText.name = `digit-${i}`;
        if (i > 0) {
          if (i === 3) {
            const baseWidthElement = this._poolDigits.get(0)!.width;
            const prevWidthElement = this._poolDigits.get(2)!.width;
            accWeight += prevWidthElement + this._config.gapX - baseWidthElement;
          } else {
            accWeight += this._config.gapX;
          }
        }
        bitmapText.x = accWeight;

        this._view.addChild(bitmapText);
        this._poolDigits.set(i, bitmapText);
      }

      this._view.alpha = this._config.defaultShow;
    } catch (error) {
      console.error('Failed to initialize counter:', error);
    }
  }

  updateCounter(text: string): void {
    if (this._isDestroyed || text.length < 5) return;

    try {
      const digits = [text[0], text[1], '.', text[3], text[4]];

      for (let i = 0; i < this._currentDigits.length; i++) {
        if (digits[i] !== this._currentDigits[i]) {
          this._poolDigits.get(i)!.text = digits[i];
          this._currentDigits[i] = digits[i];
        }
      }
    } catch (error) {
      console.error('Failed to update counter:', error);
    }
  }
  updateColor(interval: number): void {
    if (this._isDestroyed || !this._config) return;
    if (this._interval === interval) return;

    try {
      this._poolDigits.forEach((text) => {
        console.error(text);
        if (!text) return;
        text.tint = this._config.tint[interval];
        this._interval = interval;
      });
    } catch (error) {
      console.error('Failed to update color:', error);
    }
  }

  play(): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      this._view.scale.set(this._config.defaultScale.x, this._config.defaultScale.y);
      const tl = gsap.timeline();

      tl.to(this._view, { alpha: 1, duration: 0.3 }, 0).to(this._view.scale, { x: 1, y: 1, duration: 2 }, 0);

      return tl;
    } catch (error) {
      console.error('Failed to play animation:', error);
      return gsap.timeline();
    }
  }

  stop(duration = 0.4): gsap.core.Timeline {
    if (this._isDestroyed) return gsap.timeline();

    try {
      const tl = gsap.timeline();

      tl.to(this._view.scale, { x: 0, y: 0, duration }, 0).to(this._view, { alpha: 0, duration }, 0);

      return tl;
    } catch (error) {
      console.error('Failed to stop animation:', error);
      return gsap.timeline();
    }
  }

  animateScale(): void {
    if (this._isDestroyed) return;

    try {
      const lt = gsap.timeline();

      lt.addLabel('start', 0)
        .to(this._view.scale, { x: 1, y: 1, duration: 0.2, ease: 'power1.inOut' }, 'start')
        .to(this._view.scale, { x: '+=0.25', y: '+=0.25', duration: 0.2, ease: 'power1.inOut' }, 'start')
        .addLabel('down', 0.2)
        .to(this._view.scale, { x: '-=0.4', y: '-=0.4', duration: 0.2, ease: 'power1.inOut' }, 'down')
        .addLabel('up', 0.4)
        .to(this._view.scale, { x: 1, y: 1, duration: 0.2, ease: 'power1.inOut' }, 'up');
    } catch (error) {
      console.error('Failed to animate scale:', error);
    }
  }

  adaptive(): void {
    if (this._isDestroyed || !this._view) return;

    try {
      this._view.pivot.set(this._view.width / 2, this._view.height);
    } catch (error) {
      console.error('Failed to adapt counter:', error);
    }
  }

  destroy(): boolean {
    if (this._isDestroyed) return true;

    try {
      this._isDestroyed = true;
      // Kill any ongoing GSAP animations
      if (this._view) {
        gsap.killTweensOf(this._view);
        gsap.killTweensOf(this._view.scale);
      }

      if (this._view) {
        this._view.destroy({ children: true });
        this._view = new Container();
      }
      return true;
    } catch (error) {
      console.error('Failed to destroy counter:', error);
      return false;
    }
  }
}
