import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { EAppRouterUrl } from '@app/AppRouter';
import { DailyRewardsIcon, PopupIllustration } from '@shared/assets';
import { useUtils } from '@shared/hooks/utils/useUtils';
import { Wrapper } from '@shared/ui';

export const TaskInfoBanner = ({ availableTaskCount }: { availableTaskCount: number }) => {
  const { t } = useTranslation();
  const { toClick, setReturnDailyRewards } = useUtils();

  return (
    <Wrapper>
      <div className="flex flex-col relative items-center py-[5px]">
        <NavLink
          to={EAppRouterUrl.dailyRewards}
          onClick={(event) =>
            toClick(event, EAppRouterUrl.dailyRewards, {
              action: () => {
                setReturnDailyRewards(EAppRouterUrl.tasks);
              },
            })
          }
        >
          <DailyRewardsIcon className="absolute w-[50px] h-[50px] top-[5px] right-[5px]" />
        </NavLink>
        <PopupIllustration className="w-8/12 h-auto" />
        <p className="font-bold text-[18px]">
          {availableTaskCount ? t('task:available', { count: availableTaskCount }) : t('task:noAvailable')}
        </p>
      </div>
    </Wrapper>
  );
};
