import { Howl } from 'howler';
import { Assets } from 'pixi.js';
import { useEffect, useState } from 'react';

import { soundsAssets } from '@shared/configs/assets.config';
import { useStorage } from '@shared/hooks/integration/useStorage';
import { EKeyStorage } from '@shared/types/storage.types';
import { getBoolean } from '@shared/utils/helpers.utils';

import { useUserInteraction } from '../../providers/UserInteractionProvider';

export enum ESounds {
  ambientPianoBPM82 = 'ambientPianoBPM82',
  dubai = 'Dubai',
  '0060' = '0060',
}

export enum ESamples {
  buttonClick = 'buttonClick',
  buttonConfirm = 'buttonConfirm',
  errorMessage = 'errorMessage',
  notification = 'notification',
  popupMessage = 'popupMessage',
}

// Singleton для аудио-менеджера
class AudioManager {
  private static instance: AudioManager;
  private sound?: Howl;
  private sample?: Howl;
  private currentSoundId = 0;
  private isInitialized = false;
  private readonly baseSoundVolume = 0.5;
  private readonly baseSampleVolume = 0.2;

  private constructor() {}

  static getInstance(): AudioManager {
    if (!AudioManager.instance) {
      AudioManager.instance = new AudioManager();
    }
    return AudioManager.instance;
  }

  async initialize(enabledSound: boolean, enabledSamples: boolean) {
    if (this.isInitialized) return;

    const musicData = Assets.cache.get(soundsAssets.urls.musicData);
    const samplesData = Assets.cache.get(soundsAssets.urls.samplesData);

    if (!musicData?.sprite || !samplesData?.sprite) {
      throw new Error('Audio assets not loaded');
    }

    this.sound = new Howl({
      src: soundsAssets.urls.music,
      sprite: musicData.sprite,
      autoplay: false,
      preload: true,
      html5: true,
    });

    this.sample = new Howl({
      src: soundsAssets.urls.samples,
      sprite: samplesData.sprite,
      autoplay: false,
      preload: true,
      html5: true,
    });

    this.isInitialized = true;
    this.soundMute(!enabledSound);
    this.samplesMute(!enabledSamples);
  }

  soundMute(mute: boolean) {
    if (!this.sound) return;
    this.sound.mute(mute);
  }

  samplesMute(mute: boolean) {
    if (!this.sample) return;
    this.sample.mute(mute);
  }

  soundPlay(key: ESounds, volume = this.baseSoundVolume, loop = true) {
    if (!this.sound || !this.isInitialized) return;
    this.sound.volume(volume);
    const id = this.sound.play(key);
    this.currentSoundId = id;
    this.sound.loop(loop, id);
  }

  samplePlay(key: ESamples, volume = this.baseSampleVolume) {
    if (!this.sample || !this.isInitialized) return;
    const id = this.sample.play(key);
    this.sample.volume(volume, id);
  }

  currentSoundPause() {
    if (!this.sound || !this.currentSoundId || !this.isInitialized) return;
    this.sound.pause(this.currentSoundId);
  }

  currentSoundResume() {
    if (!this.sound || !this.currentSoundId || !this.isInitialized) return;
    this.sound.play(this.currentSoundId);
  }

  get isReady() {
    return this.isInitialized;
  }
}

export const useAudio = () => {
  const [audioManager] = useState(() => AudioManager.getInstance());
  const { getItem } = useStorage();
  const { hasInteracted } = useUserInteraction();

  const getSettings = async (): Promise<{ enabledSound: boolean; enabledSamples: boolean }> => {
    let enabledSound = true;
    let enabledSamples = true;

    await getItem(EKeyStorage.enabledSound).then((value) => {
      value !== null && (enabledSound = getBoolean(value, true));
    });
    await getItem(EKeyStorage.enabledSamples).then((value) => {
      value !== null && (enabledSamples = getBoolean(value, true));
    });

    return { enabledSound, enabledSamples };
  };

  const initAudio = async () => {
    if (!getBoolean(import.meta.env.VITE_APP_ENABLE_AUDIO, true) || !hasInteracted) {
      return;
    }

    try {
      const { enabledSound, enabledSamples } = await getSettings();
      await audioManager.initialize(enabledSound, enabledSamples);
    } catch (error) {
      console.error('Failed to initialize audio:', error);
    }
  };

  useEffect(() => {
    if (hasInteracted && !audioManager.isReady) {
      initAudio();
    }
  }, [hasInteracted]);

  return {
    initAudio,
    soundPlay: (key: ESounds, volume?: number, loop?: boolean) => {
      if (!hasInteracted) return;
      audioManager.soundPlay(key, volume, loop);
    },
    samplePlay: (key: ESamples, volume?: number) => {
      if (!hasInteracted) return;
      audioManager.samplePlay(key, volume);
    },
    currentSoundPause: () => audioManager.currentSoundPause(),
    currentSoundResume: () => {
      if (!hasInteracted) return;
      audioManager.currentSoundResume();
    },
    soundMute: (mute: boolean) => audioManager.soundMute(mute),
    samplesMute: (mute: boolean) => audioManager.samplesMute(mute),
  };
};
